import React, { HTMLProps } from 'react'
import {
  ChangeSubscriptionMutationFn,
  ChangeSubscriptionPreviewMutationFn,
  useChangeSubscriptionMutation,
  useChangeSubscriptionPreviewMutation,
  useCancelSubscriptionMutation,
  useReactivateSubscriptionMutation,
} from '../../bindings'
import { useAsyncState, useAsyncMethod } from '../../utils/hook'
import { LoadingButton } from '../Button'
import invoke from 'lodash/invoke'
import gql from 'graphql-tag'

type CancelSubscriptionButtonProps = HTMLProps<HTMLButtonElement> & {
  subscriptionId: string
  onConfirm?: () => Promise<boolean>
  onSuccess?: () => void
  onError?: (e: any) => void
}

export const CancelSubscriptionButton = ({
  className,
  subscriptionId,
  onConfirm,
  ...props
}: CancelSubscriptionButtonProps) => {
  const [mutate, { loading }] = useCancelSubscriptionMutation()
  const onClick = async () => {
    try {
      if (!mutate) return
      if (onConfirm && !(await onConfirm())) return
      await mutate({ variables: { subscriptionId } })
      invoke(props, 'onSuccess')
    } catch (e) {
      invoke(props, 'onError', e)
    }
  }
  return (
    <LoadingButton loading={loading} className={className} onClick={onClick} fallback="Cancelling">
      Cancel
    </LoadingButton>
  )
}

type ReactivateSubscriptionButtonProps = HTMLProps<HTMLButtonElement> & {
  subscriptionId: string
  onConfirm?: () => Promise<boolean>
  onSuccess?: () => void
  onError?: (e: any) => void
}

export const ReactivateSubscriptionButton = ({
  className,
  subscriptionId,
  onConfirm,
  ...props
}: ReactivateSubscriptionButtonProps) => {
  const [mutate, { loading }] = useReactivateSubscriptionMutation()
  const onClick = async () => {
    try {
      if (!mutate) return
      if (onConfirm && !(await onConfirm())) return
      await mutate({ variables: { subscriptionId } })
      invoke(props, 'onSuccess')
    } catch (e) {
      invoke(props, 'onError', e)
    }
  }
  return (
    <LoadingButton loading={loading} className={className} onClick={onClick} fallback="Reactivating">
      Reactivate
    </LoadingButton>
  )
}

type ChangeSubscriptionButtonProps = HTMLProps<HTMLButtonElement> & {
  onSelectPaymentCard: () => Promise<string>
  onSuccess?: () => void
  subscriptionId: string
  planId: string
}

export const ChangeSubscriptionButton = ({
  onSelectPaymentCard,
  subscriptionId,
  className,
  planId,
  ...props
}: ChangeSubscriptionButtonProps) => {
  const [changeSubscription] = useChangeSubscriptionMutation()
  const [changeSubscriptionPreview] = useChangeSubscriptionPreviewMutation()
  const [state, controls] = useAsyncState()
  const handleClick = (
    changeSubscriptionPreview: ChangeSubscriptionPreviewMutationFn,
    changeSubscription: ChangeSubscriptionMutationFn
  ) => async () => {
    try {
      controls.setLoading()
      const variables = { subscriptionId, planId }
      const result = await changeSubscriptionPreview({ variables })
      controls.setData({})
      if (!result || !result.data || !result.data.changeSubscriptionPreview) {
        return
      }
      const paymentCardId = await onSelectPaymentCard()
      if (!paymentCardId) return
      controls.setLoading()
      await changeSubscription({ variables: { ...variables, paymentCardId } })
      controls.setData({})
      invoke(props, 'onSuccess')
    } catch (e) {
      controls.setError(e.message)
    }
  }
  return (
    <LoadingButton
      loading={state.loading}
      className={className}
      onClick={handleClick(changeSubscriptionPreview, changeSubscription)}
      fallback="Changing"
    >
      Change
    </LoadingButton>
  )
}

export const RedeemQuery = gql`
  query Redeem($email: String!) {
    redeem(email: $email) @client
  }
`

export const RedeemSubscription = ({ email, onComplete, onError, client, ...props }: any) => {
  const [state, redeem] = useAsyncMethod(() => {
    return client
      .query({
        query: RedeemQuery,
        variables: { email },
      })
      .then((v) => {
        if (typeof onComplete === 'function') onComplete(v)
      })
      .catch((e) => {
        if (typeof onError === 'function') onError(e)
      })
  })
  return (
    <button onClick={redeem} disabled={state.loading} {...props}>
      Redeem Subscription
    </button>
  )
}
