import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Firebase timestamp custom scalar type */
  FirebaseTimestamp: any;
  Date: any;
  ObjectId: any;
};










export type Query = {
  __typename?: 'Query';
  empty?: Maybe<Scalars['String']>;
  about?: Maybe<About>;
  me?: Maybe<Customer>;
  account?: Maybe<Account>;
  customer?: Maybe<Customer>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  findAccounts?: Maybe<Array<Maybe<Account>>>;
  userInfoPage?: Maybe<UserInfoPage>;
  recoverAccounts?: Maybe<Array<Maybe<UserInfo>>>;
  customerProfileByUid?: Maybe<Profile>;
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  subscription?: Maybe<RebillySubscription>;
  subscriptions?: Maybe<Array<Maybe<RebillySubscription>>>;
  manySubscriptions?: Maybe<RebillySubscriptionPage>;
  customFields?: Maybe<TransactionCustomFieldsResult>;
  transaction?: Maybe<Transaction>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  refundRequest?: Maybe<RefundRequest>;
  refundRequests?: Maybe<RefundRequestPage>;
  appleSubscription?: Maybe<AppleSubscription>;
  androidSubscription?: Maybe<AndroidSubscription>;
  listTags?: Maybe<ShipStationTagPage>;
  listStores?: Maybe<ShipStationStorePage>;
  listCarriers?: Maybe<ShipStationCarrierPage>;
  listWarehouses?: Maybe<ShipStationWarehousePage>;
  listOrdersByTag?: Maybe<ShipStationOrderPage>;
  listOrders?: Maybe<ShipStationOrderPage>;
  listShipments?: Maybe<ShipStationShipmentPage>;
  listFulfillments?: Maybe<ShipStationFulfillmentPage>;
  listProducts?: Maybe<ShipStationProductPage>;
  getCarrier?: Maybe<ShipStationCarrier>;
  getOrder?: Maybe<ShipStationOrder>;
  getParcel?: Maybe<SendCloudParcel>;
  plan?: Maybe<Plan>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  coinPlans?: Maybe<Array<Maybe<Plan>>>;
  subscriptionCount?: Maybe<Scalars['Int']>;
  cumulativeSubscriptionCount?: Maybe<CumulativeSubscriptionCountResult>;
  cumulativeTransactionCount?: Maybe<CumulativeTransactionCountResult>;
  kycDocumentById?: Maybe<KycDocument>;
};


export type QueryCustomerArgs = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryCustomersArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
  uids?: Maybe<Array<Scalars['String']>>;
  emails?: Maybe<Array<Scalars['String']>>;
};


export type QueryAccountsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
  uids?: Maybe<Array<Scalars['String']>>;
  emails?: Maybe<Array<Scalars['String']>>;
};


export type QueryFindAccountsArgs = {
  search: Scalars['String'];
};


export type QueryUserInfoPageArgs = {
  filter?: Maybe<UserInfoFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
};


export type QueryRecoverAccountsArgs = {
  emails?: Maybe<Array<Scalars['String']>>;
};


export type QueryCustomerProfileByUidArgs = {
  uid: Scalars['String'];
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryInvoicesArgs = {
  customerId: Scalars['String'];
};


export type QuerySubscriptionArgs = {
  id: Scalars['String'];
};


export type QuerySubscriptionsArgs = {
  customerId: Scalars['String'];
};


export type QueryManySubscriptionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  planType?: Maybe<Scalars['String']>;
};


export type QueryCustomFieldsArgs = {
  customFields?: Maybe<TransactionCustomFields>;
};


export type QueryTransactionArgs = {
  id: Scalars['String'];
};


export type QueryTransactionsArgs = {
  customerId: Scalars['String'];
};


export type QueryRefundRequestArgs = {
  id: Scalars['String'];
};


export type QueryRefundRequestsArgs = {
  filter?: Maybe<RefundRequestFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
};


export type QueryAppleSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryAndroidSubscriptionArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type QueryListStoresArgs = {
  filter?: Maybe<ShipStationStoresFilter>;
};


export type QueryListOrdersByTagArgs = {
  filter: ShipStationOrderByTagFilter;
  pagination?: Maybe<Pagination>;
};


export type QueryListOrdersArgs = {
  filter?: Maybe<ShipStationOrderFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
};


export type QueryListShipmentsArgs = {
  filter?: Maybe<ShipStationShipmentsFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
};


export type QueryListFulfillmentsArgs = {
  filter?: Maybe<ShipStationFulfillmentsFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
};


export type QueryListProductsArgs = {
  filter?: Maybe<ShipStationProductFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
};


export type QueryGetCarrierArgs = {
  carrierCode: Scalars['String'];
};


export type QueryGetOrderArgs = {
  orderId: Scalars['Float'];
};


export type QueryGetParcelArgs = {
  parcelId?: Maybe<Scalars['Float']>;
  trackingNumber?: Maybe<Scalars['String']>;
};


export type QueryPlanArgs = {
  id: Scalars['String'];
};


export type QueryCoinPlansArgs = {
  planType?: Maybe<Scalars['String']>;
};


export type QuerySubscriptionCountArgs = {
  planIds?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};


export type QueryCumulativeSubscriptionCountArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type QueryCumulativeTransactionCountArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type QueryKycDocumentByIdArgs = {
  id: Scalars['String'];
};

export type About = {
  __typename?: 'About';
  name?: Maybe<Scalars['String']>;
  git?: Maybe<Git>;
};

export type Git = {
  __typename?: 'Git';
  sha?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String'];
  customerIds: Array<Maybe<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['JSON']>;
  primaryAddress?: Maybe<Address>;
  lifetimeRevenue?: Maybe<CurrencyAmount>;
  invoiceCount?: Maybe<Scalars['String']>;
  averageValue?: Maybe<CurrencyAmount>;
  paymentCount?: Maybe<Scalars['String']>;
  lastPaymentTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  websiteId?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  defaultPaymentInstrument?: Maybe<PaymentInstrumentId>;
  paymentCards?: Maybe<Array<Maybe<PaymentCard>>>;
  paymentInstruments?: Maybe<Array<Maybe<PaymentCard>>>;
  subscriptions?: Maybe<Array<Maybe<RebillySubscription>>>;
  mainSubscription?: Maybe<RebillySubscription>;
  pastSubscriptions?: Maybe<Array<Maybe<RebillySubscription>>>;
  activeSubscriptionInfo?: Maybe<ActiveSubscriptionInfo>;
  isMasterEligible?: Maybe<MasterEligibility>;
  invoices?: Maybe<InvoicesPage>;
  invoicesPastDue?: Maybe<InvoicesPastDuePage>;
  userInfo?: Maybe<UserInfo>;
  user?: Maybe<Profile>;
  kycMaxAttempts?: Maybe<KycMaxAttempts>;
  kycDocuments?: Maybe<Array<Maybe<KycDocument>>>;
};


export type CustomerInvoicesArgs = {
  pagination?: Maybe<Pagination>;
  filter?: Maybe<InvoiceFilter>;
};


export type CustomerInvoicesPastDueArgs = {
  pagination?: Maybe<Pagination>;
};


export type Address = {
  __typename?: 'Address';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Email>>>;
};

export type Email = {
  __typename?: 'Email';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount';
  currency?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

export type PaymentInstrumentId = {
  __typename?: 'PaymentInstrumentId';
  method?: Maybe<Scalars['String']>;
  paymentCardId?: Maybe<Scalars['String']>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  id?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['Int']>;
  expMonth?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankCountry?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  panFingerprint?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['JSON']>;
  billingAddress?: Maybe<Address>;
  stickyGatewayAccountId?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  billingContactId?: Maybe<Scalars['String']>;
  billingContact?: Maybe<Scalars['String']>;
};

export type RebillySubscription = {
  __typename?: 'RebillySubscription';
  id: Scalars['String'];
  orderType?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  websiteId?: Maybe<Scalars['String']>;
  initialInvoice?: Maybe<Invoice>;
  recentInvoice?: Maybe<Invoice>;
  upcomingInvoice?: Maybe<Invoice>;
  initialInvoiceId?: Maybe<Scalars['String']>;
  recentInvoiceId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  activationTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  renewalTime?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  autopay?: Maybe<Scalars['Boolean']>;
  billingAddress?: Maybe<Address>;
  deliveryAddress?: Maybe<Address>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  inTrial?: Maybe<Scalars['Boolean']>;
  rebillNumber?: Maybe<Scalars['Int']>;
  poNumber?: Maybe<Scalars['Int']>;
  recurringInterval?: Maybe<Scalars['Int']>;
  billingAnchor?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<Scalars['String']>;
  canceledTime?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<Scalars['String']>;
  cancelCategory?: Maybe<Scalars['String']>;
  cancelDescription?: Maybe<Scalars['String']>;
  riskMetadata?: Maybe<RiskMetadata>;
  items?: Maybe<Array<Maybe<SubscriptionItem>>>;
  customFields?: Maybe<Scalars['JSON']>;
  planId?: Maybe<Scalars['String']>;
  planIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  planType?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  dueTime?: Maybe<Scalars['String']>;
  issuedTime?: Maybe<Scalars['String']>;
  abandonedTime?: Maybe<Scalars['String']>;
  voidedTime?: Maybe<Scalars['String']>;
  paidTime?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['String']>;
  websiteId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  deliveryAddress?: Maybe<Address>;
  amount?: Maybe<Scalars['Float']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  shippingAmount?: Maybe<Scalars['Float']>;
  poNumber?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<InvoiceItem>>>;
  discountAmount?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Scalars['JSON']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['JSON']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  autopayScheduledTime?: Maybe<Scalars['String']>;
  autopayRetryNumber?: Maybe<Scalars['Int']>;
  retryInstruction?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  delinquentCollectionPeriod?: Maybe<Scalars['Int']>;
  collectionPeriod?: Maybe<Scalars['Int']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  periodStartTime?: Maybe<Scalars['String']>;
  periodEndTime?: Maybe<Scalars['String']>;
  periodNumber?: Maybe<Scalars['Int']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  rebillNumber?: Maybe<Scalars['Int']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  parentTransactionId?: Maybe<Scalars['String']>;
  childTransactions?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscriptionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  planIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isRebill?: Maybe<Scalars['Boolean']>;
  rebillNumber?: Maybe<Scalars['Int']>;
  gatewayAccountId?: Maybe<Scalars['String']>;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  gatewayName?: Maybe<Scalars['String']>;
  acquirerName?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['JSON']>;
  customerId?: Maybe<Scalars['String']>;
  websiteId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  has3ds?: Maybe<Scalars['Boolean']>;
  hasDcc?: Maybe<Scalars['Boolean']>;
  dcc?: Maybe<Scalars['String']>;
  velocity?: Maybe<Scalars['Int']>;
  bin?: Maybe<Scalars['String']>;
  paymentInstrumentFingerprint?: Maybe<Scalars['String']>;
  riskScore?: Maybe<Scalars['String']>;
  scheduledTime?: Maybe<Scalars['String']>;
  processedTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRetry?: Maybe<Scalars['Boolean']>;
  retriesResult?: Maybe<Scalars['String']>;
  retryInstruction?: Maybe<Scalars['String']>;
  retryNumber?: Maybe<Scalars['Int']>;
  retriedTransactionId?: Maybe<Scalars['String']>;
  paymentInstrument?: Maybe<PaymentInstrumentId>;
  notificationUrl?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  redirectUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  riskMetadata?: Maybe<RiskMetadata>;
  isDisputed?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  hadDiscrepancy?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['JSON']>;
  hasBumpOffer?: Maybe<Scalars['Boolean']>;
  bumpOffer?: Maybe<Scalars['JSON']>;
  billingDescriptor?: Maybe<Scalars['JSON']>;
  revision?: Maybe<Scalars['Int']>;
  referenceData?: Maybe<Scalars['JSON']>;
  method?: Maybe<Scalars['String']>;
  gatewayResponse?: Maybe<Scalars['JSON']>;
  customer?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  paymentCardId?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<Scalars['String']>;
  billingContactId?: Maybe<Scalars['String']>;
};

export type RiskMetadata = {
  __typename?: 'RiskMetadata';
  ipAddress?: Maybe<Scalars['String']>;
  isVpn?: Maybe<Scalars['Boolean']>;
  isProxy?: Maybe<Scalars['Boolean']>;
  isTor?: Maybe<Scalars['Boolean']>;
  isHosting?: Maybe<Scalars['Boolean']>;
  vpnServiceName?: Maybe<Scalars['String']>;
  isp?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  accuracyRadius?: Maybe<Scalars['Float']>;
  postalCode?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  httpHeaders?: Maybe<Scalars['JSON']>;
  distance?: Maybe<Scalars['Float']>;
};

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  id?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  billingTiming?: Maybe<Scalars['String']>;
};

export type ActiveSubscriptionInfo = {
  __typename?: 'ActiveSubscriptionInfo';
  email?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  checkInDate?: Maybe<Scalars['String']>;
  activeSubscription?: Maybe<Scalars['String']>;
  previousSubscription?: Maybe<Scalars['String']>;
  subscriptionInfo?: Maybe<Scalars['JSON']>;
  ownerUID?: Maybe<Scalars['String']>;
};

export type MasterEligibility = {
  __typename?: 'MasterEligibility';
  value?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type Pagination = {
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type InvoiceFilter = {
  subscriptionId?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoicesPage = {
  __typename?: 'InvoicesPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Invoice>>>;
};

export type InvoicesPastDuePage = {
  __typename?: 'InvoicesPastDuePage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Invoice>>>;
  error?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  lastMaphash?: Maybe<Scalars['String']>;
  xyoRedeemed?: Maybe<Scalars['Float']>;
  xyoPending?: Maybe<Scalars['Float']>;
  xyoRedeemPending?: Maybe<Scalars['Float']>;
  xyoDropped?: Maybe<Scalars['Float']>;
  xyoCollected?: Maybe<Scalars['Float']>;
  xyoGifted?: Maybe<Scalars['Float']>;
  xyoPickedUp?: Maybe<Scalars['Float']>;
  xyoGeomined?: Maybe<Scalars['Float']>;
  xyoSurveys?: Maybe<Scalars['Float']>;
  xyoInbrainRevoked?: Maybe<Scalars['Float']>;
  xyoGeoclaimed?: Maybe<Scalars['Float']>;
  xyoHodlRewards?: Maybe<Scalars['Float']>;
  xyoBossBalance?: Maybe<Scalars['Float']>;
  xyoBossGifted?: Maybe<Scalars['Float']>;
  xyoExpired?: Maybe<Scalars['Float']>;
  xyoRestored?: Maybe<Scalars['Float']>;
  xyoSweepstakes?: Maybe<Scalars['Float']>;
  xyoEnabled?: Maybe<Scalars['Boolean']>;
  nfcScanCount?: Maybe<Scalars['Float']>;
  hodlLastCollectedYYYYMMDD?: Maybe<Scalars['String']>;
  recordVersion?: Maybe<Scalars['Float']>;
  remineMarker?: Maybe<Scalars['String']>;
  recentCount?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  checkInDate?: Maybe<Scalars['FirebaseTimestamp']>;
  created?: Maybe<Scalars['FirebaseTimestamp']>;
  appVersion?: Maybe<Scalars['String']>;
  referrerUid?: Maybe<Scalars['String']>;
  addedToDrip?: Maybe<Scalars['Int']>;
  mineCountNoSentinel?: Maybe<Scalars['Int']>;
  ibeacons?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastMinedWithSentinelBle?: Maybe<Scalars['FirebaseTimestamp']>;
  remineCount?: Maybe<Scalars['Int']>;
  subscriptionInfo?: Maybe<Scalars['JSON']>;
  osDevMode?: Maybe<Scalars['Boolean']>;
  ownerUID?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  mineCount?: Maybe<Scalars['Int']>;
  recentMarker?: Maybe<Scalars['String']>;
  mineCountSentinel?: Maybe<Scalars['Int']>;
  direction?: Maybe<Scalars['String']>;
  activeSubscription?: Maybe<Scalars['String']>;
  activeSubscriptionLock?: Maybe<Scalars['Boolean']>;
  subActive?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  lastMinedYYYYMMDD?: Maybe<Scalars['Int']>;
  verifiedAddressesTotal?: Maybe<Scalars['Float']>;
  sentinels?: Maybe<Scalars['Int']>;
  random?: Maybe<Scalars['Int']>;
  liteOk?: Maybe<Scalars['Boolean']>;
  masterOk?: Maybe<Scalars['Boolean']>;
  kycComplete?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  ethAddress?: Maybe<Scalars['String']>;
  lastKnownAddress?: Maybe<Scalars['String']>;
  coinbaseEmail?: Maybe<Scalars['String']>;
  shipStreetAddress?: Maybe<Scalars['String']>;
  webDropsAllowed?: Maybe<Scalars['Boolean']>;
  webDropsAmountMax?: Maybe<Scalars['Float']>;
  webDropsQuantityMax?: Maybe<Scalars['Int']>;
  lastMined?: Maybe<Scalars['FirebaseTimestamp']>;
  profileImageExists?: Maybe<Scalars['Boolean']>;
  profileImageUpdated?: Maybe<Scalars['String']>;
  subOrder?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  lastSync?: Maybe<Scalars['String']>;
  ssid?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Float']>;
  appsflyerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  didReceiveReturningUserGift?: Maybe<Scalars['Boolean']>;
  achievementsCollected?: Maybe<Array<Maybe<AchievementCollected>>>;
};


export type AchievementCollected = {
  __typename?: 'AchievementCollected';
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  achievement?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  info?: Maybe<Achievement>;
};

export type Achievement = {
  __typename?: 'Achievement';
  id?: Maybe<Scalars['String']>;
  achievement?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  userInfo?: Maybe<UserInfo>;
  customer?: Maybe<Customer>;
  inAppSubscription?: Maybe<InAppSubscription>;
};

export type InAppSubscription = {
  __typename?: 'InAppSubscription';
  subDate?: Maybe<Scalars['String']>;
  subCreatedDate?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subActive?: Maybe<Scalars['Boolean']>;
  subOs?: Maybe<Scalars['String']>;
  subSku?: Maybe<Scalars['String']>;
  subOrder?: Maybe<Scalars['String']>;
};

export type KycMaxAttempts = {
  __typename?: 'KycMaxAttempts';
  attempts?: Maybe<Scalars['Int']>;
  attemptsToday?: Maybe<Scalars['Int']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  maxAttemptsToday?: Maybe<Scalars['Int']>;
};

export type KycDocument = {
  __typename?: 'KycDocument';
  id: Scalars['String'];
  fileId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  processedTime?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<RejectionReason>;
  documentMatches?: Maybe<DocumentMatches>;
  download?: Maybe<Scalars['JSON']>;
};

export type RejectionReason = {
  __typename?: 'RejectionReason';
  type?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentMatches = {
  __typename?: 'DocumentMatches';
  score?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['JSON']>;
};

export type Account = {
  __typename?: 'Account';
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  user?: Maybe<Profile>;
  userInfo?: Maybe<UserInfo>;
  customer?: Maybe<Customer>;
};

export type UserInfoFilter = {
  ownerUID?: Maybe<StringFilter>;
  suspended?: Maybe<StringFilter>;
  referrerUid?: Maybe<StringFilter>;
  deviceId?: Maybe<StringFilter>;
};

export type StringFilter = {
  EQ?: Maybe<Scalars['String']>;
  GT?: Maybe<Scalars['String']>;
  GTE?: Maybe<Scalars['String']>;
  IN?: Maybe<Array<Maybe<Scalars['String']>>>;
  ALL?: Maybe<Array<Maybe<Scalars['String']>>>;
  LT?: Maybe<Scalars['String']>;
  LTE?: Maybe<Scalars['String']>;
  NE?: Maybe<Scalars['String']>;
  NIN?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Sort = {
  field?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type UserInfoPage = {
  __typename?: 'UserInfoPage';
  data?: Maybe<Array<Maybe<UserInfo>>>;
  total?: Maybe<Scalars['Int']>;
};

export type RebillySubscriptionPage = {
  __typename?: 'RebillySubscriptionPage';
  total?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<RebillySubscription>>>;
};

export type TransactionCustomFields = {
  device?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  API?: Maybe<Scalars['String']>;
  affiliate_id?: Maybe<Scalars['String']>;
  aff_id?: Maybe<Scalars['String']>;
  aff_sub?: Maybe<Scalars['String']>;
  aff_sub2?: Maybe<Scalars['String']>;
  aff_sub3?: Maybe<Scalars['String']>;
  aff_sub4?: Maybe<Scalars['String']>;
  aff_sub5?: Maybe<Scalars['String']>;
  aff_click_id?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['String']>;
  advertiser_id?: Maybe<Scalars['String']>;
  tune_transaction_id?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  gclsrc?: Maybe<Scalars['String']>;
  ddcref?: Maybe<Scalars['String']>;
  req_id?: Maybe<Scalars['String']>;
  camp_id?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['String']>;
  lpid?: Maybe<Scalars['String']>;
  sub_id?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  oid?: Maybe<Scalars['String']>;
  shipStationStoreId?: Maybe<Scalars['String']>;
  coin_uid?: Maybe<Scalars['String']>;
  sweepstakeSku?: Maybe<Scalars['String']>;
};

export type TransactionCustomFieldsResult = {
  __typename?: 'TransactionCustomFieldsResult';
  device?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  API?: Maybe<Scalars['String']>;
  affiliate_id?: Maybe<Scalars['String']>;
  aff_id?: Maybe<Scalars['String']>;
  aff_sub?: Maybe<Scalars['String']>;
  aff_sub2?: Maybe<Scalars['String']>;
  aff_sub3?: Maybe<Scalars['String']>;
  aff_sub4?: Maybe<Scalars['String']>;
  aff_sub5?: Maybe<Scalars['String']>;
  aff_click_id?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['String']>;
  advertiser_id?: Maybe<Scalars['String']>;
  tune_transaction_id?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  gclsrc?: Maybe<Scalars['String']>;
  ddcref?: Maybe<Scalars['String']>;
  req_id?: Maybe<Scalars['String']>;
  camp_id?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['String']>;
  lpid?: Maybe<Scalars['String']>;
  sub_id?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  oid?: Maybe<Scalars['String']>;
  shipStationStoreId?: Maybe<Scalars['String']>;
  coin_uid?: Maybe<Scalars['String']>;
  sweepstakeSku?: Maybe<Scalars['String']>;
};

export type RefundRequest = {
  __typename?: 'RefundRequest';
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['Float']>;
  refunded?: Maybe<Scalars['Boolean']>;
  refunding?: Maybe<Scalars['Boolean']>;
  refundedDate?: Maybe<Scalars['String']>;
  acceptedDate?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  activeSubscription?: Maybe<Scalars['String']>;
  iosSub?: Maybe<Scalars['JSON']>;
  iosErr?: Maybe<Scalars['String']>;
  androidSub?: Maybe<Scalars['JSON']>;
  androidErr?: Maybe<Scalars['String']>;
  rebillySub?: Maybe<RebillySub>;
  rebillyErr?: Maybe<Scalars['String']>;
  eligibleMessage?: Maybe<Scalars['String']>;
  eligibleMonths?: Maybe<Scalars['Int']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  collectedAmount?: Maybe<Scalars['Float']>;
  collectedAmountRange?: Maybe<Scalars['JSON']>;
  collectedCount?: Maybe<Scalars['Int']>;
  droppedAmount?: Maybe<Scalars['Float']>;
  dropCount?: Maybe<Scalars['Int']>;
  redeemCount?: Maybe<Scalars['Int']>;
  xyoCollected?: Maybe<Scalars['Float']>;
  resetXyoCollectedAmount?: Maybe<Scalars['Float']>;
};

export type RebillySub = {
  __typename?: 'RebillySub';
  subscriptionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<RebillyTrans>>;
};

export type RebillyTrans = {
  __typename?: 'RebillyTrans';
  createdTime?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  amount: Scalars['Float'];
};

export type RefundRequestFilter = {
  uid?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  type?: Maybe<StringFilter>;
  active?: Maybe<Scalars['Boolean']>;
  accepted?: Maybe<Scalars['Boolean']>;
  refunding?: Maybe<Scalars['Boolean']>;
  refunded?: Maybe<Scalars['Boolean']>;
};

export type RefundRequestPage = {
  __typename?: 'RefundRequestPage';
  data?: Maybe<Array<Maybe<RefundRequest>>>;
  total?: Maybe<Scalars['Int']>;
};

export type AppleSubscription = {
  __typename?: 'AppleSubscription';
  id?: Maybe<Scalars['String']>;
};

export type AndroidSubscription = {
  __typename?: 'AndroidSubscription';
  acknowledgementState?: Maybe<Scalars['Float']>;
  autoRenewing?: Maybe<Scalars['Boolean']>;
  autoResumeTimeMillis?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['Float']>;
  cancelSurveyResult?: Maybe<AndroidSubscriptionCancelSurveyResult>;
  countryCode?: Maybe<Scalars['String']>;
  developerPayload?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  expiryTimeMillis?: Maybe<Scalars['String']>;
  externalAccountId?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  introductoryPriceInfo?: Maybe<AndroidIntroductoryPriceInfo>;
  kind?: Maybe<Scalars['String']>;
  linkedPurchaseToken?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['Float']>;
  priceAmountMicros?: Maybe<Scalars['String']>;
  priceChange?: Maybe<AndroidSubscriptionPriceChange>;
  priceCurrencyCode?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  profileName?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  promotionType?: Maybe<Scalars['Float']>;
  purchaseType?: Maybe<Scalars['Float']>;
  startTimeMillis?: Maybe<Scalars['String']>;
  userCancellationTimeMillis?: Maybe<Scalars['String']>;
};

export type AndroidSubscriptionCancelSurveyResult = {
  __typename?: 'AndroidSubscriptionCancelSurveyResult';
  cancelSurveyReason?: Maybe<Scalars['Float']>;
  userInputCancelReason?: Maybe<Scalars['String']>;
};

export type AndroidIntroductoryPriceInfo = {
  __typename?: 'AndroidIntroductoryPriceInfo';
  introductoryPriceAmountMicros?: Maybe<Scalars['String']>;
  introductoryPriceCurrencyCode?: Maybe<Scalars['String']>;
  introductoryPriceCycles?: Maybe<Scalars['Float']>;
  introductoryPricePeriod?: Maybe<Scalars['String']>;
};

export type AndroidSubscriptionPriceChange = {
  __typename?: 'AndroidSubscriptionPriceChange';
  newPrice?: Maybe<AndroidSubscriptionPrice>;
  state?: Maybe<Scalars['Float']>;
};

export type AndroidSubscriptionPrice = {
  __typename?: 'AndroidSubscriptionPrice';
  currency?: Maybe<Scalars['String']>;
  priceMicros?: Maybe<Scalars['String']>;
};

export type ShipStationTagPage = {
  __typename?: 'ShipStationTagPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationTag>>>;
};

export type ShipStationTag = {
  __typename?: 'ShipStationTag';
  id?: Maybe<Scalars['Int']>;
  tagId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type ShipStationStoresFilter = {
  showInactive?: Maybe<Scalars['Boolean']>;
  marketplaceId?: Maybe<Scalars['Int']>;
};

export type ShipStationStorePage = {
  __typename?: 'ShipStationStorePage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationStore>>>;
};

export type ShipStationStore = {
  __typename?: 'ShipStationStore';
  id?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeName?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['Int']>;
  marketplaceName?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  integrationUrl?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  publicEmail?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  refreshDate?: Maybe<Scalars['String']>;
  lastRefreshAttempt?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  modifyDate?: Maybe<Scalars['String']>;
  autoRefresh?: Maybe<Scalars['Boolean']>;
};

export type ShipStationCarrierPage = {
  __typename?: 'ShipStationCarrierPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationCarrier>>>;
};

export type ShipStationCarrier = {
  __typename?: 'ShipStationCarrier';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  requiresFundedAccount?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Scalars['Float']>;
  nickname?: Maybe<Scalars['String']>;
  shippingProviderId?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  packages?: Maybe<Array<Maybe<ShipStationCarrierPackage>>>;
  services?: Maybe<Array<Maybe<ShipStationCarrierService>>>;
};

export type ShipStationCarrierPackage = {
  __typename?: 'ShipStationCarrierPackage';
  carrierCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  domestic?: Maybe<Scalars['Boolean']>;
  international?: Maybe<Scalars['Boolean']>;
};

export type ShipStationCarrierService = {
  __typename?: 'ShipStationCarrierService';
  carrierCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  domestic?: Maybe<Scalars['Boolean']>;
  international?: Maybe<Scalars['Boolean']>;
};

export type ShipStationWarehousePage = {
  __typename?: 'ShipStationWarehousePage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationWarehouse>>>;
};

export type ShipStationWarehouse = {
  __typename?: 'ShipStationWarehouse';
  id?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseName?: Maybe<Scalars['String']>;
  originAddress?: Maybe<ShipStationAddress>;
  returnAddress?: Maybe<ShipStationAddress>;
  createDate?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ShipStationAddress = {
  __typename?: 'ShipStationAddress';
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  residential?: Maybe<Scalars['Boolean']>;
  addressVerified?: Maybe<Scalars['String']>;
};

export type ShipStationOrderByTagFilter = {
  tagId: Scalars['Int'];
  orderStatus: ShipStationOrderStatus;
};

export enum ShipStationOrderStatus {
  AwaitingPayment = 'awaiting_payment',
  AwaitingShipment = 'awaiting_shipment',
  Shipped = 'shipped',
  OnHold = 'on_hold',
  Cancelled = 'cancelled'
}

export type ShipStationOrderPage = {
  __typename?: 'ShipStationOrderPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationOrder>>>;
};

export type ShipStationOrder = {
  __typename?: 'ShipStationOrder';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderKey?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  modifyDate?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  shipByDate?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<ShipStationOrderStatus>;
  customerId?: Maybe<Scalars['Float']>;
  customerUsername?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  billTo?: Maybe<ShipStationAddress>;
  shipTo?: Maybe<ShipStationAddress>;
  items?: Maybe<Array<Maybe<ShipStationOrderItem>>>;
  orderTotal?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  shippingAmount?: Maybe<Scalars['Float']>;
  customerNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  gift?: Maybe<Scalars['Boolean']>;
  giftMessage?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  requestedShippingService?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  packageCode?: Maybe<Scalars['String']>;
  confirmation?: Maybe<ShipStationConfirmation>;
  shipDate?: Maybe<Scalars['String']>;
  holdUntilDate?: Maybe<Scalars['String']>;
  weight?: Maybe<ShipStationWeight>;
  dimensions?: Maybe<ShipStationDimensions>;
  insuranceOptions?: Maybe<ShipStationInsuranceOptions>;
  internationalOptions?: Maybe<ShipStationInternationalOptions>;
  advancedOptions?: Maybe<ShipStationAdvancedOptions>;
  tagIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  userId?: Maybe<Scalars['String']>;
  externallyFulfilled?: Maybe<Scalars['Boolean']>;
  externallyFulfilledBy?: Maybe<Scalars['String']>;
};

export type ShipStationOrderItem = {
  __typename?: 'ShipStationOrderItem';
  orderItemId?: Maybe<Scalars['Float']>;
  lineItemKey?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  weight?: Maybe<ShipStationWeight>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  shippingAmount?: Maybe<Scalars['Float']>;
  warehouseLocation?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<ShipStationOrderItemOption>>>;
  productId?: Maybe<Scalars['Float']>;
  fulfillmentSku?: Maybe<Scalars['String']>;
  adjustment?: Maybe<Scalars['Boolean']>;
  upc?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  modifyDate?: Maybe<Scalars['String']>;
};

export type ShipStationWeight = {
  __typename?: 'ShipStationWeight';
  value?: Maybe<Scalars['Float']>;
  units?: Maybe<ShipStationWeightUnit>;
  WeightUnits?: Maybe<Scalars['Float']>;
};

export enum ShipStationWeightUnit {
  Pounds = 'pounds',
  Ounces = 'ounces',
  Grams = 'grams'
}

export type ShipStationOrderItemOption = {
  __typename?: 'ShipStationOrderItemOption';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ShipStationConfirmation {
  None = 'none',
  Delivery = 'delivery',
  Signature = 'signature',
  AdultSignature = 'adult_signature',
  DirectSignature = 'direct_signature'
}

export type ShipStationDimensions = {
  __typename?: 'ShipStationDimensions';
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  units?: Maybe<ShipStationDimensionUnit>;
};

export enum ShipStationDimensionUnit {
  Inches = 'inches',
  Centimeters = 'centimeters'
}

export type ShipStationInsuranceOptions = {
  __typename?: 'ShipStationInsuranceOptions';
  provider?: Maybe<Scalars['String']>;
  insureShipment?: Maybe<Scalars['Boolean']>;
  insuredValue?: Maybe<Scalars['Float']>;
};

export type ShipStationInternationalOptions = {
  __typename?: 'ShipStationInternationalOptions';
  contents?: Maybe<ShipStationInternationalContents>;
  customsItems?: Maybe<Array<Maybe<ShipStationCustomsItem>>>;
  nonDelivery?: Maybe<ShipStationInternationalNonDelivery>;
};

export enum ShipStationInternationalContents {
  Merchandise = 'merchandise',
  Documents = 'documents',
  Gift = 'gift',
  ReturnedGoods = 'returned_goods',
  Sample = 'sample'
}

export type ShipStationCustomsItem = {
  __typename?: 'ShipStationCustomsItem';
  customsItemId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  harmonizedTariffCode?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
};

export enum ShipStationInternationalNonDelivery {
  ReturnToSender = 'return_to_sender',
  TreatAsAbandoned = 'treat_as_abandoned'
}

export type ShipStationAdvancedOptions = {
  __typename?: 'ShipStationAdvancedOptions';
  warehouseId?: Maybe<Scalars['Int']>;
  nonMachinable?: Maybe<Scalars['Boolean']>;
  saturdayDelivery?: Maybe<Scalars['Boolean']>;
  containsAlcohol?: Maybe<Scalars['Boolean']>;
  storeId?: Maybe<Scalars['Int']>;
  customField1?: Maybe<Scalars['String']>;
  customField2?: Maybe<Scalars['String']>;
  customField3?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  mergedOrSplit?: Maybe<Scalars['Boolean']>;
  mergedIds?: Maybe<Array<Maybe<Scalars['Float']>>>;
  parentId?: Maybe<Scalars['Float']>;
  billToParty?: Maybe<Scalars['String']>;
  billToAccount?: Maybe<Scalars['String']>;
  billToPostalCode?: Maybe<Scalars['String']>;
  billToCountryCode?: Maybe<Scalars['String']>;
  billToMyOtherAccount?: Maybe<Scalars['String']>;
};

export type ShipStationOrderFilter = {
  customerName?: Maybe<Scalars['String']>;
  itemKeyword?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
  orderStatus?: Maybe<ShipStationOrderStatus>;
  orderNumber?: Maybe<Scalars['String']>;
  createDateStart?: Maybe<Scalars['String']>;
  createDateEnd?: Maybe<Scalars['String']>;
  modifyDateStart?: Maybe<Scalars['String']>;
  modifyDateEnd?: Maybe<Scalars['String']>;
  orderDateStart?: Maybe<Scalars['String']>;
  orderDateEnd?: Maybe<Scalars['String']>;
  paymentDateStart?: Maybe<Scalars['String']>;
  paymentDateEnd?: Maybe<Scalars['String']>;
};

export type ShipStationShipmentsFilter = {
  recipientName?: Maybe<Scalars['String']>;
  recipientCountryCode?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Float']>;
  carrierCode?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  createDateStart?: Maybe<Scalars['String']>;
  createDateEnd?: Maybe<Scalars['String']>;
  shipDateStart?: Maybe<Scalars['String']>;
  shipDateEnd?: Maybe<Scalars['String']>;
  voidDateStart?: Maybe<Scalars['String']>;
  voidDateEnd?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
  includeShipmentItems?: Maybe<Scalars['Boolean']>;
};

export type ShipStationShipmentPage = {
  __typename?: 'ShipStationShipmentPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationShipment>>>;
};

export type ShipStationShipment = {
  __typename?: 'ShipStationShipment';
  id?: Maybe<Scalars['Float']>;
  shipmentId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderKey?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  shipDate?: Maybe<Scalars['String']>;
  shipmentCost?: Maybe<Scalars['Float']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  trackingNumber?: Maybe<Scalars['String']>;
  isReturnLabel?: Maybe<Scalars['Boolean']>;
  batchNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  packageCode?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  confirmation?: Maybe<ShipStationConfirmation>;
  warehouseId?: Maybe<Scalars['Int']>;
  voided?: Maybe<Scalars['Boolean']>;
  voidDate?: Maybe<Scalars['String']>;
  marketplaceNotified?: Maybe<Scalars['Boolean']>;
  notifyErrorMessage?: Maybe<Scalars['String']>;
  shipTo?: Maybe<ShipStationAddress>;
  weight?: Maybe<ShipStationWeight>;
  dimensions?: Maybe<ShipStationDimensions>;
  insuranceOptions?: Maybe<ShipStationInsuranceOptions>;
  advancedOptions?: Maybe<ShipStationAdvancedOptions>;
  shipmentItems?: Maybe<Array<Maybe<ShipStationOrderItem>>>;
  labelData?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['String']>;
};

export type ShipStationFulfillmentsFilter = {
  fulfillmentId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderNumber?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  createDateStart?: Maybe<Scalars['String']>;
  createDateEnd?: Maybe<Scalars['String']>;
  shipDateStart?: Maybe<Scalars['String']>;
  shipDateEnd?: Maybe<Scalars['String']>;
};

export type ShipStationFulfillmentPage = {
  __typename?: 'ShipStationFulfillmentPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationFulfillment>>>;
};

export type ShipStationFulfillment = {
  __typename?: 'ShipStationFulfillment';
  id?: Maybe<Scalars['Float']>;
  fulfillmentId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderNumber?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  shipDate?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  fulfillmentProviderCode?: Maybe<Scalars['String']>;
  fulfillmentServiceCode?: Maybe<Scalars['String']>;
  fulfillmentFee?: Maybe<Scalars['Float']>;
  voidRequested?: Maybe<Scalars['Boolean']>;
  voided?: Maybe<Scalars['Boolean']>;
  marketplaceNotified?: Maybe<Scalars['Boolean']>;
  notifyErrorMessage?: Maybe<Scalars['String']>;
  shipTo?: Maybe<ShipStationAddress>;
};

export type ShipStationProductFilter = {
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['String']>;
  productTypeId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  showInactive?: Maybe<Scalars['String']>;
};

export type ShipStationProductPage = {
  __typename?: 'ShipStationProductPage';
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ShipStationProduct>>>;
};

export type ShipStationProduct = {
  __typename?: 'ShipStationProduct';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  defaultCost?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  weightOz?: Maybe<Scalars['Float']>;
  internalNotes?: Maybe<Scalars['String']>;
  fulfillmentSku?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  modifyDate?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  productCategory?: Maybe<ShipStationProductCategory>;
  productType?: Maybe<Scalars['String']>;
  warehouseLocation?: Maybe<Scalars['String']>;
  defaultCarrierCode?: Maybe<Scalars['String']>;
  defaultServiceCode?: Maybe<Scalars['String']>;
  defaultPackageCode?: Maybe<Scalars['String']>;
  defaultIntlCarrierCode?: Maybe<Scalars['String']>;
  defaultIntlServiceCode?: Maybe<Scalars['String']>;
  defaultIntlPackageCode?: Maybe<Scalars['String']>;
  defaultConfirmation?: Maybe<Scalars['String']>;
  defaultIntlConfirmation?: Maybe<Scalars['String']>;
  customsDescription?: Maybe<Scalars['String']>;
  customsValue?: Maybe<Scalars['Float']>;
  customsTariffNo?: Maybe<Scalars['String']>;
  customsCountryCode?: Maybe<Scalars['String']>;
  noCustoms?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<ShipStationProductTag>;
};

export type ShipStationProductCategory = {
  __typename?: 'ShipStationProductCategory';
  categoryId?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type ShipStationProductTag = {
  __typename?: 'ShipStationProductTag';
  tagId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type SendCloudParcel = {
  __typename?: 'SendCloudParcel';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address_divided?: Maybe<SendCloudAddressDivided>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['String']>;
  tracking_number?: Maybe<Scalars['String']>;
  tracking_url?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  label?: Maybe<SendCloudLabel>;
  customs_declaration?: Maybe<Scalars['JSON']>;
  status?: Maybe<SendCloudStatus>;
  data?: Maybe<Scalars['JSON']>;
  country?: Maybe<SendCloudCountry>;
  shipment?: Maybe<SendCloudShipment>;
  order_number?: Maybe<Scalars['String']>;
  shipment_uuid?: Maybe<Scalars['String']>;
  external_order_id?: Maybe<Scalars['String']>;
  external_shipment_id?: Maybe<Scalars['String']>;
  external_reference?: Maybe<Scalars['String']>;
  parcel_items?: Maybe<Array<Maybe<SendCloudParcelItem>>>;
};

export type SendCloudAddressDivided = {
  __typename?: 'SendCloudAddressDivided';
  street?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
};

export type SendCloudLabel = {
  __typename?: 'SendCloudLabel';
  normal_printer?: Maybe<Array<Maybe<Scalars['String']>>>;
  label_printer?: Maybe<Scalars['String']>;
};

export type SendCloudStatus = {
  __typename?: 'SendCloudStatus';
  id?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
};

export type SendCloudCountry = {
  __typename?: 'SendCloudCountry';
  iso_3?: Maybe<Scalars['String']>;
  iso_2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SendCloudShipment = {
  __typename?: 'SendCloudShipment';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type SendCloudParcelItem = {
  __typename?: 'SendCloudParcelItem';
  description?: Maybe<Scalars['String']>;
  hs_code?: Maybe<Scalars['String']>;
  origin_country?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSON']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productOptions?: Maybe<Scalars['JSON']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  currencySign?: Maybe<Scalars['String']>;
  setup?: Maybe<Setup>;
  trial?: Maybe<Trial>;
  pricing?: Maybe<Pricing>;
  recurringInterval?: Maybe<RecurringInterval>;
  billingAnchor?: Maybe<BillingAnchor>;
  meteredBilling?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  richDescription?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  subscriptionCount?: Maybe<Scalars['Int']>;
  customFields?: Maybe<Scalars['JSON']>;
  setupAmount?: Maybe<Scalars['Float']>;
  trialAmount?: Maybe<Scalars['Float']>;
  trialPeriodUnit?: Maybe<Scalars['String']>;
  trialPeriodLength?: Maybe<Scalars['Int']>;
  recurringAmount?: Maybe<Scalars['Float']>;
  recurringPeriodUnit?: Maybe<Scalars['String']>;
  recurringPeriodLength?: Maybe<Scalars['Int']>;
  recurringPeriodLimit?: Maybe<Scalars['Int']>;
  contractTermUnit?: Maybe<Scalars['Float']>;
  contractTermLength?: Maybe<Scalars['Int']>;
  minQuantity?: Maybe<Scalars['Int']>;
  maxQuantity?: Maybe<Scalars['Int']>;
  expireTime?: Maybe<Scalars['String']>;
  expiredTime?: Maybe<Scalars['String']>;
  planType?: Maybe<Scalars['String']>;
};

export type Setup = {
  __typename?: 'Setup';
  price?: Maybe<Scalars['Float']>;
};

export type Trial = {
  __typename?: 'Trial';
  price?: Maybe<Scalars['Float']>;
  period?: Maybe<TrialPeriod>;
};

export type TrialPeriod = {
  __typename?: 'TrialPeriod';
  unit?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  formula?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  maxQuantity?: Maybe<Scalars['Int']>;
};

export type RecurringInterval = {
  __typename?: 'RecurringInterval';
  unit?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  billingTime?: Maybe<Scalars['String']>;
  periodAnchorInstruction?: Maybe<InstructionMethod>;
};

export type InstructionMethod = {
  __typename?: 'InstructionMethod';
  method?: Maybe<Scalars['String']>;
};

export type BillingAnchor = {
  __typename?: 'BillingAnchor';
  chronology?: Maybe<Scalars['String']>;
  billingAnchorInstruction?: Maybe<InstructionMethod>;
};

export type CumulativeSubscriptionCountResult = {
  __typename?: 'CumulativeSubscriptionCountResult';
  data?: Maybe<Array<Maybe<CumulativeSubscriptionCount>>>;
};

export type CumulativeSubscriptionCount = {
  __typename?: 'CumulativeSubscriptionCount';
  day?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  newCount?: Maybe<Scalars['Int']>;
  canceledCount?: Maybe<Scalars['Int']>;
  netCount?: Maybe<Scalars['Int']>;
  cumulativeCount?: Maybe<Scalars['Int']>;
};

export type CumulativeTransactionCountResult = {
  __typename?: 'CumulativeTransactionCountResult';
  data?: Maybe<Array<Maybe<CumulativeTransactionCount>>>;
};

export type CumulativeTransactionCount = {
  __typename?: 'CumulativeTransactionCount';
  aggregationFieldValue?: Maybe<Scalars['String']>;
  authApprovedThroughput?: Maybe<Scalars['Float']>;
  approvedThroughput?: Maybe<Scalars['Float']>;
  authApprovalCount?: Maybe<Scalars['Int']>;
  disputesRate?: Maybe<Scalars['Float']>;
  disputesCount?: Maybe<Scalars['Int']>;
  salesCount?: Maybe<Scalars['Int']>;
  salesValue?: Maybe<Scalars['Float']>;
  salesAverage?: Maybe<Scalars['Float']>;
  refundsCount?: Maybe<Scalars['Int']>;
  refundsValue?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  empty?: Maybe<Scalars['String']>;
  createCustomer?: Maybe<Customer>;
  addPayment?: Maybe<Customer>;
  redeem?: Maybe<Array<Maybe<ActiveSubscriptionInfo>>>;
  createSubscription?: Maybe<RebillySubscription>;
  cancelSubscriptions?: Maybe<Array<Maybe<RebillySubscription>>>;
  cancelSubscription?: Maybe<RebillySubscription>;
  reactivateSubscription?: Maybe<RebillySubscription>;
  changeSubscriptionPreview?: Maybe<ChangeSubscription>;
  changeSubscription?: Maybe<RebillySubscription>;
  createTransaction?: Maybe<Transaction>;
  generateRefundRequest?: Maybe<RefundRequest>;
  processRefundRequest?: Maybe<RefundRequest>;
  processedRefundRequest?: Maybe<RefundRequest>;
  deactivateRefundRequest?: Maybe<RefundRequest>;
  activateRefundRequest?: Maybe<RefundRequest>;
  refundTransaction?: Maybe<Transaction>;
  deactivatePaymentCard?: Maybe<PaymentCard>;
  checkout: CheckoutResponse;
  cancelOrder?: Maybe<ShipStationOrder>;
  deleteOrder?: Maybe<ShipStationOrder>;
  holdOrder?: Maybe<ShipStationOrder>;
  restoreOrderFromHold?: Maybe<ShipStationOrder>;
  voidLabel?: Maybe<Scalars['Boolean']>;
  calculateRates?: Maybe<Array<Maybe<ShipStationRate>>>;
  markOrderAsShipped?: Maybe<MarkedShipped>;
  createLabelForOrder?: Maybe<ShipStationLabel>;
  sendToSendCloud?: Maybe<SendToSendCloudResult>;
  updateOrderNotes?: Maybe<ShipStationOrder>;
  updateOrderItems?: Maybe<ShipStationOrder>;
  updateOrderAddress?: Maybe<ShipStationOrder>;
  batchCreateSendCloudLabelsForOrder?: Maybe<BatchSendCloudLabelWithParcel>;
  createSendCloudLabelsForOrder?: Maybe<Array<Maybe<SendCloudLabelWithParcel>>>;
  createKycDocument?: Maybe<KycDocumentResult>;
  updateKycDocument?: Maybe<KycDocumentResult>;
  acceptKycDocument?: Maybe<KycDocument>;
  rejectKycDocument?: Maybe<KycDocument>;
};


export type MutationCreateCustomerArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


export type MutationAddPaymentArgs = {
  paymentToken: Scalars['String'];
  customer?: Maybe<CustomerInput>;
  customerId?: Maybe<Scalars['String']>;
};


export type MutationRedeemArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
  uids?: Maybe<Array<Scalars['String']>>;
  emails?: Maybe<Array<Scalars['String']>>;
  dry?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateSubscriptionArgs = {
  subscription: SubscriptionInput;
};


export type MutationCancelSubscriptionsArgs = {
  subscriptionIds: Array<Scalars['String']>;
  churnTime?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prorated?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['String'];
  churnTime?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prorated?: Maybe<Scalars['Boolean']>;
};


export type MutationReactivateSubscriptionArgs = {
  subscriptionId: Scalars['String'];
  effectiveTime?: Maybe<Scalars['String']>;
  renewalTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationChangeSubscriptionPreviewArgs = {
  subscriptionId: Scalars['String'];
  planId: Scalars['String'];
  renewalPolicy?: Maybe<Scalars['String']>;
  effectiveTime?: Maybe<Scalars['String']>;
  prorated?: Maybe<Scalars['Boolean']>;
};


export type MutationChangeSubscriptionArgs = {
  subscriptionId: Scalars['String'];
  planId: Scalars['String'];
  paymentInstrument?: Maybe<PaymentInstrumentInput>;
  renewalPolicy?: Maybe<Scalars['String']>;
  effectiveTime?: Maybe<Scalars['String']>;
  prorated?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateTransactionArgs = {
  invoiceId?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerInput>;
  customFields?: Maybe<TransactionCustomFields>;
  paymentInstrument?: Maybe<PaymentInstrumentInput>;
  paymentInstruction?: Maybe<PaymentInstructionInput>;
};


export type MutationGenerateRefundRequestArgs = {
  uid: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  expireSeconds?: Maybe<Scalars['Int']>;
};


export type MutationProcessRefundRequestArgs = {
  id: Scalars['String'];
};


export type MutationProcessedRefundRequestArgs = {
  id: Scalars['String'];
};


export type MutationDeactivateRefundRequestArgs = {
  id: Scalars['String'];
};


export type MutationActivateRefundRequestArgs = {
  id: Scalars['String'];
};


export type MutationRefundTransactionArgs = {
  id: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
};


export type MutationDeactivatePaymentCardArgs = {
  paymentCardId: Scalars['String'];
};


export type MutationCheckoutArgs = {
  planId?: Maybe<Scalars['String']>;
  items?: Maybe<Array<ItemInput>>;
  coupons?: Maybe<Array<CouponInput>>;
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerInput>;
  shipping?: Maybe<AddressInput>;
  billing?: Maybe<AddressInput>;
  paymentToken?: Maybe<Scalars['String']>;
  paymentInstrument?: Maybe<PaymentInstrumentInput>;
  paymentInstruction?: Maybe<PaymentInstructionInput>;
  customFields?: Maybe<TransactionCustomFields>;
};


export type MutationCancelOrderArgs = {
  orderId: Scalars['Float'];
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['Float'];
};


export type MutationHoldOrderArgs = {
  orderId: Scalars['Float'];
  holdUntilDate: Scalars['String'];
};


export type MutationRestoreOrderFromHoldArgs = {
  orderId: Scalars['Float'];
};


export type MutationVoidLabelArgs = {
  shipmentId: Scalars['Float'];
  orderId: Scalars['Float'];
};


export type MutationCalculateRatesArgs = {
  input: CalculateRatesInput;
};


export type MutationMarkOrderAsShippedArgs = {
  input: MarkShippedInput;
};


export type MutationCreateLabelForOrderArgs = {
  input: CreateLabelInput;
};


export type MutationSendToSendCloudArgs = {
  orderId: Scalars['Float'];
};


export type MutationUpdateOrderNotesArgs = {
  orderId: Scalars['Float'];
  input: OrderNotesInput;
};


export type MutationUpdateOrderItemsArgs = {
  orderId: Scalars['Float'];
  items: Array<OrderItemInput>;
};


export type MutationUpdateOrderAddressArgs = {
  orderId: Scalars['Float'];
  input: OrderAddressInput;
};


export type MutationBatchCreateSendCloudLabelsForOrderArgs = {
  orderNumbers: Array<Scalars['String']>;
};


export type MutationCreateSendCloudLabelsForOrderArgs = {
  orderNumbers: Array<Scalars['String']>;
};


export type MutationCreateKycDocumentArgs = {
  input: KycDocumentInput;
};


export type MutationUpdateKycDocumentArgs = {
  id: Scalars['String'];
  input: KycDocumentInput;
};


export type MutationAcceptKycDocumentArgs = {
  id: Scalars['String'];
};


export type MutationRejectKycDocumentArgs = {
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SubscriptionInput = {
  orderType: Scalars['String'];
  customerId: Scalars['String'];
  items: Array<ItemInput>;
  autopay?: Maybe<Scalars['Boolean']>;
  customFields?: Maybe<TransactionCustomFields>;
};

export type ItemInput = {
  planId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ChangeSubscription = {
  __typename?: 'ChangeSubscription';
  id: Scalars['String'];
  items: Array<Item>;
  lineItems: Array<LineItem>;
  lineItemSubtotal: Scalars['Float'];
  orderType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  websiteId?: Maybe<Scalars['String']>;
  initialInvoiceId?: Maybe<Scalars['String']>;
  recentInvoiceId?: Maybe<Scalars['String']>;
  billingAnchor?: Maybe<BillingAnchor>;
  recurringInterval?: Maybe<RecurringInterval>;
  autopay?: Maybe<Scalars['Boolean']>;
  riskMetadata?: Maybe<RiskMetadata>;
  startTime?: Maybe<Scalars['String']>;
  activationTime?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['JSON']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  canceledTime?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<Scalars['String']>;
  cancelCategory?: Maybe<Scalars['String']>;
  cancelDescription?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  planId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitPriceAmount?: Maybe<Scalars['Float']>;
  unitPriceCurrency?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  periodStartTime?: Maybe<Scalars['String']>;
  periodEndTime?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['String']>;
};

export type PaymentInstrumentInput = {
  method?: Maybe<Scalars['String']>;
  paymentCardId?: Maybe<Scalars['String']>;
};

export type PaymentInstructionInput = {
  token?: Maybe<Scalars['String']>;
  paymentInstrumentId?: Maybe<Scalars['String']>;
};

export type CouponInput = {
  couponId: Scalars['String'];
  discountsPerRedemption?: Maybe<DiscountsPerRedemption>;
  minimumOrderAmount?: Maybe<MinimumOrderAmount>;
  redemptionsPerCustomer?: Maybe<RedemptionsPerCustomer>;
  restrictToInvoices?: Maybe<RestrictToInvoices>;
  restrictToPlans?: Maybe<RestrictToPlans>;
  restrictToSubscriptions?: Maybe<RestrictToSubscriptions>;
  restrictToProducts?: Maybe<RestrictToProducts>;
  totalRedemptions?: Maybe<TotalRedemptions>;
  paidByTime?: Maybe<PaidByTime>;
};

export type DiscountsPerRedemption = {
  quantity: Scalars['Int'];
};

export type MinimumOrderAmount = {
  amount: Scalars['Int'];
  currency: Scalars['String'];
};

export type RedemptionsPerCustomer = {
  quantity: Scalars['Int'];
};

export type RestrictToInvoices = {
  invoiceIds: Array<Scalars['String']>;
};

export type RestrictToPlans = {
  planIds: Array<Scalars['String']>;
};

export type RestrictToSubscriptions = {
  subscriptionIds: Array<Scalars['String']>;
};

export type RestrictToProducts = {
  productIds: Array<Scalars['String']>;
};

export type TotalRedemptions = {
  quantity: Scalars['Int'];
};

export type PaidByTime = {
  time: Scalars['String'];
};

export type AddressInput = {
  organization?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  region: Scalars['String'];
  postalCode: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};

export type CheckoutResponse = {
  __typename?: 'CheckoutResponse';
  customer?: Maybe<Customer>;
  transaction?: Maybe<Transaction>;
};

export type CalculateRatesInput = {
  carrierCode: Scalars['String'];
  fromPostalCode: Scalars['String'];
  toPostalCode: Scalars['String'];
  toCountry: Scalars['String'];
  weight: ShipStationWeightInput;
  serviceCode?: Maybe<Scalars['String']>;
  packageCode?: Maybe<Scalars['String']>;
  toState?: Maybe<Scalars['String']>;
  toCity?: Maybe<Scalars['String']>;
  dimensions?: Maybe<ShipStationDimensionsInput>;
  confirmation?: Maybe<ShipStationConfirmation>;
  residential?: Maybe<Scalars['Boolean']>;
};

export type ShipStationWeightInput = {
  units?: Maybe<ShipStationWeightUnit>;
  value?: Maybe<Scalars['Float']>;
  WeightUnits?: Maybe<Scalars['Int']>;
};

export type ShipStationDimensionsInput = {
  units?: Maybe<ShipStationDimensionUnit>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type ShipStationRate = {
  __typename?: 'ShipStationRate';
  serviceName?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  shipmentCost?: Maybe<Scalars['Float']>;
  otherCost?: Maybe<Scalars['Float']>;
};

export type MarkShippedInput = {
  orderId: Scalars['Float'];
  carrierCode: Scalars['String'];
  shipDate?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  notifyCustomer?: Maybe<Scalars['Boolean']>;
  notifySalesChannel?: Maybe<Scalars['Boolean']>;
};

export type MarkedShipped = {
  __typename?: 'MarkedShipped';
  orderId?: Maybe<Scalars['Float']>;
  carrierCode?: Maybe<Scalars['String']>;
  shipDate?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  notifyCustomer?: Maybe<Scalars['Boolean']>;
  notifySalesChannel?: Maybe<Scalars['Boolean']>;
};

export type CreateLabelInput = {
  orderId: Scalars['Float'];
  carrierCode: Scalars['String'];
  serviceCode: Scalars['String'];
  confirmation: Scalars['String'];
  shipDate: Scalars['String'];
  packageCode?: Maybe<Scalars['String']>;
  weight?: Maybe<ShipStationWeightInput>;
  dimensions?: Maybe<ShipStationDimensionsInput>;
  insuranceOptions?: Maybe<ShipStationInsuranceOptionsInput>;
  internationalOptions?: Maybe<ShipStationInternationalOptionsInput>;
  advancedOptions?: Maybe<ShipStationAdvancedOptionsInput>;
  testLabel: Scalars['Boolean'];
};

export type ShipStationInsuranceOptionsInput = {
  provider?: Maybe<Scalars['String']>;
  insureShipment?: Maybe<Scalars['Boolean']>;
  insuredValue?: Maybe<Scalars['Float']>;
};

export type ShipStationInternationalOptionsInput = {
  contents?: Maybe<Scalars['String']>;
  nonDelivery?: Maybe<Scalars['String']>;
  customsItems?: Maybe<Array<Maybe<ShipStationCustomsItemInput>>>;
};

export type ShipStationCustomsItemInput = {
  customsItemId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  harmonizedTariffCode?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
};

export type ShipStationAdvancedOptionsInput = {
  warehouseId?: Maybe<Scalars['Int']>;
};

export type ShipStationLabel = {
  __typename?: 'ShipStationLabel';
  shipmentId?: Maybe<Scalars['Float']>;
  shipmentCost?: Maybe<Scalars['Float']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  trackingNumber?: Maybe<Scalars['String']>;
  labelData?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['String']>;
};

export type SendToSendCloudResult = {
  __typename?: 'SendToSendCloudResult';
  orderId?: Maybe<Scalars['Float']>;
  order?: Maybe<ShipStationOrder>;
  parcel?: Maybe<SendCloudParcel>;
};

export type OrderNotesInput = {
  internalNotes?: Maybe<Scalars['String']>;
  customerNotes?: Maybe<Scalars['String']>;
  giftMessage?: Maybe<Scalars['String']>;
  gift?: Maybe<Scalars['Boolean']>;
};

export type OrderItemInput = {
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  weight?: Maybe<ShipStationWeightInput>;
  productId?: Maybe<Scalars['Float']>;
};

export type OrderAddressInput = {
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type BatchSendCloudLabelWithParcel = {
  __typename?: 'BatchSendCloudLabelWithParcel';
  batch?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<SendCloudLabelWithParcel>>>;
};

export type SendCloudLabelWithParcel = {
  __typename?: 'SendCloudLabelWithParcel';
  parcelId?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  parcel?: Maybe<SendCloudParcel>;
  normal_printer?: Maybe<Array<Maybe<Scalars['String']>>>;
  label_printer?: Maybe<Scalars['String']>;
};

export type KycDocumentInput = {
  file: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  documentType?: Maybe<KycDocumentType>;
};

export enum KycDocumentType {
  IdentityProof = 'identityProof',
  AddressProof = 'addressProof'
}

export type KycDocumentResult = {
  __typename?: 'KycDocumentResult';
  customerId?: Maybe<Scalars['String']>;
  kycDocument?: Maybe<KycDocument>;
  kycMaxAttempts?: Maybe<KycMaxAttempts>;
};



export type IntFilter = {
  EQ?: Maybe<Scalars['Int']>;
  GT?: Maybe<Scalars['Int']>;
  GTE?: Maybe<Scalars['Int']>;
  IN?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ALL?: Maybe<Array<Maybe<Scalars['Int']>>>;
  LT?: Maybe<Scalars['Int']>;
  LTE?: Maybe<Scalars['Int']>;
  NE?: Maybe<Scalars['Int']>;
  NIN?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type FloatFilter = {
  EQ?: Maybe<Scalars['Float']>;
  GT?: Maybe<Scalars['Float']>;
  GTE?: Maybe<Scalars['Float']>;
  IN?: Maybe<Array<Maybe<Scalars['Float']>>>;
  ALL?: Maybe<Array<Maybe<Scalars['Float']>>>;
  LT?: Maybe<Scalars['Float']>;
  LTE?: Maybe<Scalars['Float']>;
  NE?: Maybe<Scalars['Float']>;
  NIN?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type DateFilter = {
  EQ?: Maybe<Scalars['Date']>;
  GT?: Maybe<Scalars['Date']>;
  GTE?: Maybe<Scalars['Date']>;
  IN?: Maybe<Array<Maybe<Scalars['Date']>>>;
  ALL?: Maybe<Array<Maybe<Scalars['Date']>>>;
  LT?: Maybe<Scalars['Date']>;
  LTE?: Maybe<Scalars['Date']>;
  NE?: Maybe<Scalars['Date']>;
  NIN?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type ObjectIdFilter = {
  EQ?: Maybe<Scalars['ObjectId']>;
  GT?: Maybe<Scalars['ObjectId']>;
  GTE?: Maybe<Scalars['ObjectId']>;
  IN?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  ALL?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  LT?: Maybe<Scalars['ObjectId']>;
  LTE?: Maybe<Scalars['ObjectId']>;
  NE?: Maybe<Scalars['ObjectId']>;
  NIN?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type MonthlyActivityInfo = {
  __typename?: 'MonthlyActivityInfo';
  startDate?: Maybe<Scalars['String']>;
  collected?: Maybe<Scalars['Float']>;
  collectedCount?: Maybe<Scalars['Int']>;
  dropped?: Maybe<Scalars['Float']>;
  dropCount?: Maybe<Scalars['Int']>;
  redeemCount?: Maybe<Scalars['Int']>;
};

export enum CouponRestrictionType {
  DiscountsPerRedemption = 'DiscountsPerRedemption',
  MinimumOrderAmount = 'MinimumOrderAmount',
  RedemptionsPerCustomer = 'RedemptionsPerCustomer',
  RestrictToInvoices = 'RestrictToInvoices',
  RestrictToPlans = 'RestrictToPlans',
  RestrictToSubscriptions = 'RestrictToSubscriptions',
  RestrictToProducts = 'RestrictToProducts',
  TotalRedemptions = 'TotalRedemptions',
  PaidByTime = 'PaidByTime'
}

export type AdditionalRestriction = {
  __typename?: 'AdditionalRestriction';
  type?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  planIds?: Maybe<Array<Scalars['String']>>;
  subscriptionIds?: Maybe<Array<Scalars['String']>>;
  productIds?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  id?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  additionalRestrictions?: Maybe<Array<Maybe<AdditionalRestriction>>>;
  createdTime?: Maybe<Scalars['Date']>;
  canceledTime?: Maybe<Scalars['Date']>;
};

export type ShipStationOrderActivity = {
  __typename?: 'ShipStationOrderActivity';
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  labelData?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  changes?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type SendCloudParcelInput = {
  address?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customs_invoice_nr?: Maybe<Scalars['String']>;
  customs_shipment_type?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  external_reference?: Maybe<Scalars['String']>;
  external_order_id?: Maybe<Scalars['String']>;
  external_shipment_id?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['String']>;
  order_status?: Maybe<SendCloudParcelStatusInput>;
  parcel_items?: Maybe<Array<Maybe<SendCloudParcelItemInput>>>;
  payment_status?: Maybe<SendCloudParcelPaymentStatusInput>;
  postal_code?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['Float']>;
  shipping_method?: Maybe<Scalars['Float']>;
  shipping_method_checkout_name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  to_post_number?: Maybe<Scalars['String']>;
  to_service_point?: Maybe<Scalars['String']>;
  to_state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  request_label?: Maybe<Scalars['Boolean']>;
  shipment?: Maybe<SendCloudParcelShipmentInput>;
  request_label_async?: Maybe<Scalars['Boolean']>;
  apply_shipping_rules?: Maybe<Scalars['Boolean']>;
};

export type SendCloudParcelStatusInput = {
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SendCloudParcelItemInput = {
  description?: Maybe<Scalars['String']>;
  hs_code?: Maybe<Scalars['String']>;
  origin_country?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSON']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type SendCloudParcelPaymentStatusInput = {
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SendCloudParcelShipmentInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unitLabel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  taxCategoryId?: Maybe<Scalars['String']>;
  requiresShipping?: Maybe<Scalars['Boolean']>;
  accountingCode?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  customFields?: Maybe<Scalars['JSON']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  _links?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RebillyFile = {
  __typename?: 'RebillyFile';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  sha1?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RebillyFileInput = {
  file: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShipStationAddressFragment = (
  { __typename?: 'ShipStationAddress' }
  & Pick<ShipStationAddress, 'name' | 'company' | 'street1' | 'street2' | 'street3' | 'city' | 'state' | 'postalCode' | 'country' | 'phone' | 'residential' | 'addressVerified'>
);

export type ShipStationOrderItemFragment = (
  { __typename?: 'ShipStationOrderItem' }
  & Pick<ShipStationOrderItem, 'orderItemId' | 'lineItemKey' | 'sku' | 'name' | 'imageUrl' | 'quantity' | 'unitPrice' | 'taxAmount' | 'shippingAmount' | 'warehouseLocation' | 'productId' | 'fulfillmentSku' | 'adjustment' | 'upc' | 'createDate' | 'modifyDate'>
  & { weight?: Maybe<(
    { __typename?: 'ShipStationWeight' }
    & Pick<ShipStationWeight, 'value' | 'units'>
  )>, options?: Maybe<Array<Maybe<(
    { __typename?: 'ShipStationOrderItemOption' }
    & Pick<ShipStationOrderItemOption, 'name' | 'value'>
  )>>> }
);

export type ShipStationInsuranceOptionsFragment = (
  { __typename?: 'ShipStationInsuranceOptions' }
  & Pick<ShipStationInsuranceOptions, 'provider' | 'insureShipment' | 'insuredValue'>
);

export type ShipStationInternationalOptionsFragment = (
  { __typename?: 'ShipStationInternationalOptions' }
  & Pick<ShipStationInternationalOptions, 'contents' | 'nonDelivery'>
  & { customsItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipStationCustomsItem' }
    & Pick<ShipStationCustomsItem, 'customsItemId' | 'description' | 'quantity' | 'value' | 'harmonizedTariffCode' | 'countryOfOrigin'>
  )>>> }
);

export type ShipStationAdvancedOptionsFragment = (
  { __typename?: 'ShipStationAdvancedOptions' }
  & Pick<ShipStationAdvancedOptions, 'warehouseId' | 'nonMachinable' | 'saturdayDelivery' | 'containsAlcohol' | 'storeId' | 'customField1' | 'customField2' | 'customField3' | 'source' | 'mergedOrSplit' | 'mergedIds' | 'parentId' | 'billToParty' | 'billToAccount' | 'billToPostalCode' | 'billToCountryCode' | 'billToMyOtherAccount'>
);

export type ShipStationOrderFragment = (
  { __typename?: 'ShipStationOrder' }
  & Pick<ShipStationOrder, 'id' | 'orderId' | 'orderNumber' | 'orderKey' | 'orderDate' | 'createDate' | 'modifyDate' | 'paymentDate' | 'shipByDate' | 'orderStatus' | 'customerId' | 'customerUsername' | 'customerEmail' | 'orderTotal' | 'amountPaid' | 'taxAmount' | 'shippingAmount' | 'customerNotes' | 'internalNotes' | 'gift' | 'giftMessage' | 'paymentMethod' | 'requestedShippingService' | 'carrierCode' | 'serviceCode' | 'packageCode' | 'confirmation' | 'shipDate' | 'holdUntilDate' | 'tagIds' | 'userId' | 'externallyFulfilled' | 'externallyFulfilledBy'>
  & { billTo?: Maybe<(
    { __typename?: 'ShipStationAddress' }
    & ShipStationAddressFragment
  )>, shipTo?: Maybe<(
    { __typename?: 'ShipStationAddress' }
    & ShipStationAddressFragment
  )>, items?: Maybe<Array<Maybe<(
    { __typename?: 'ShipStationOrderItem' }
    & ShipStationOrderItemFragment
  )>>>, weight?: Maybe<(
    { __typename?: 'ShipStationWeight' }
    & Pick<ShipStationWeight, 'value' | 'units'>
  )>, dimensions?: Maybe<(
    { __typename?: 'ShipStationDimensions' }
    & Pick<ShipStationDimensions, 'length' | 'width' | 'height' | 'units'>
  )>, insuranceOptions?: Maybe<(
    { __typename?: 'ShipStationInsuranceOptions' }
    & ShipStationInsuranceOptionsFragment
  )>, internationalOptions?: Maybe<(
    { __typename?: 'ShipStationInternationalOptions' }
    & ShipStationInternationalOptionsFragment
  )>, advancedOptions?: Maybe<(
    { __typename?: 'ShipStationAdvancedOptions' }
    & ShipStationAdvancedOptionsFragment
  )> }
);

export type ShipStationShipmentFragment = (
  { __typename?: 'ShipStationShipment' }
  & Pick<ShipStationShipment, 'id' | 'shipmentId' | 'orderId' | 'orderKey' | 'userId' | 'orderNumber' | 'createDate' | 'shipDate' | 'shipmentCost' | 'insuranceCost' | 'trackingNumber' | 'isReturnLabel' | 'customerEmail' | 'batchNumber' | 'carrierCode' | 'serviceCode' | 'packageCode' | 'confirmation' | 'warehouseId' | 'voided' | 'voidDate' | 'marketplaceNotified' | 'notifyErrorMessage' | 'labelData' | 'formData'>
  & { shipTo?: Maybe<(
    { __typename?: 'ShipStationAddress' }
    & ShipStationAddressFragment
  )>, weight?: Maybe<(
    { __typename?: 'ShipStationWeight' }
    & Pick<ShipStationWeight, 'value' | 'units'>
  )>, dimensions?: Maybe<(
    { __typename?: 'ShipStationDimensions' }
    & Pick<ShipStationDimensions, 'length' | 'width' | 'height' | 'units'>
  )>, insuranceOptions?: Maybe<(
    { __typename?: 'ShipStationInsuranceOptions' }
    & ShipStationInsuranceOptionsFragment
  )>, advancedOptions?: Maybe<(
    { __typename?: 'ShipStationAdvancedOptions' }
    & ShipStationAdvancedOptionsFragment
  )>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipStationOrderItem' }
    & ShipStationOrderItemFragment
  )>>> }
);

export type ShipStationProductFragment = (
  { __typename?: 'ShipStationProduct' }
  & Pick<ShipStationProduct, 'id' | 'productId' | 'sku' | 'name' | 'price' | 'defaultCost' | 'length' | 'width' | 'height' | 'weightOz' | 'internalNotes' | 'fulfillmentSku' | 'createDate' | 'modifyDate' | 'active' | 'productType' | 'warehouseLocation' | 'defaultCarrierCode' | 'defaultServiceCode' | 'defaultPackageCode' | 'defaultIntlCarrierCode' | 'defaultIntlServiceCode' | 'defaultIntlPackageCode' | 'defaultConfirmation' | 'defaultIntlConfirmation' | 'customsDescription' | 'customsValue' | 'customsTariffNo' | 'customsCountryCode' | 'noCustoms'>
  & { productCategory?: Maybe<(
    { __typename?: 'ShipStationProductCategory' }
    & Pick<ShipStationProductCategory, 'categoryId' | 'name'>
  )>, tags?: Maybe<(
    { __typename?: 'ShipStationProductTag' }
    & Pick<ShipStationProductTag, 'tagId' | 'name'>
  )> }
);

export type ShipStationFulfillmentFragment = (
  { __typename?: 'ShipStationFulfillment' }
  & Pick<ShipStationFulfillment, 'id' | 'fulfillmentId' | 'orderId' | 'orderNumber' | 'userId' | 'customerEmail' | 'trackingNumber' | 'createDate' | 'shipDate' | 'voidDate' | 'deliveryDate' | 'carrierCode' | 'fulfillmentProviderCode' | 'fulfillmentServiceCode' | 'fulfillmentFee' | 'voidRequested' | 'voided' | 'marketplaceNotified' | 'notifyErrorMessage'>
  & { shipTo?: Maybe<(
    { __typename?: 'ShipStationAddress' }
    & ShipStationAddressFragment
  )> }
);

export type ShipStationCarrierFragment = (
  { __typename?: 'ShipStationCarrier' }
  & Pick<ShipStationCarrier, 'id' | 'name' | 'code' | 'accountNumber' | 'requiresFundedAccount' | 'balance' | 'nickname' | 'shippingProviderId' | 'primary'>
);

export type ShipStationCarrierPackageFragment = (
  { __typename?: 'ShipStationCarrierPackage' }
  & Pick<ShipStationCarrierPackage, 'carrierCode' | 'code' | 'name' | 'domestic' | 'international'>
);

export type ShipStationCarrierServiceFragment = (
  { __typename?: 'ShipStationCarrierService' }
  & Pick<ShipStationCarrierService, 'carrierCode' | 'code' | 'name' | 'domestic' | 'international'>
);

export type ShipStationStoreFragment = (
  { __typename?: 'ShipStationStore' }
  & Pick<ShipStationStore, 'id' | 'storeId' | 'storeName' | 'marketplaceId' | 'marketplaceName' | 'accountName' | 'email' | 'integrationUrl' | 'active' | 'companyName' | 'phone' | 'publicEmail' | 'website' | 'refreshDate' | 'lastRefreshAttempt' | 'createDate' | 'modifyDate' | 'autoRefresh'>
);

export type ShipStationWarehouseFragment = (
  { __typename?: 'ShipStationWarehouse' }
  & Pick<ShipStationWarehouse, 'id' | 'warehouseId' | 'warehouseName' | 'createDate' | 'isDefault'>
  & { originAddress?: Maybe<(
    { __typename?: 'ShipStationAddress' }
    & ShipStationAddressFragment
  )>, returnAddress?: Maybe<(
    { __typename?: 'ShipStationAddress' }
    & ShipStationAddressFragment
  )> }
);

export type SendCloudParcelFragment = (
  { __typename?: 'SendCloudParcel' }
  & Pick<SendCloudParcel, 'id' | 'name' | 'company_name' | 'address' | 'address2' | 'city' | 'postal_code' | 'telephone' | 'email' | 'date_created' | 'tracking_number' | 'tracking_url' | 'weight' | 'customs_declaration' | 'data' | 'order_number' | 'shipment_uuid' | 'external_order_id' | 'external_shipment_id' | 'external_reference'>
  & { address_divided?: Maybe<(
    { __typename?: 'SendCloudAddressDivided' }
    & Pick<SendCloudAddressDivided, 'street' | 'house_number'>
  )>, label?: Maybe<(
    { __typename?: 'SendCloudLabel' }
    & Pick<SendCloudLabel, 'normal_printer' | 'label_printer'>
  )>, status?: Maybe<(
    { __typename?: 'SendCloudStatus' }
    & Pick<SendCloudStatus, 'id' | 'message'>
  )>, country?: Maybe<(
    { __typename?: 'SendCloudCountry' }
    & Pick<SendCloudCountry, 'iso_3' | 'iso_2' | 'name'>
  )>, shipment?: Maybe<(
    { __typename?: 'SendCloudShipment' }
    & Pick<SendCloudShipment, 'id' | 'name'>
  )>, parcel_items?: Maybe<Array<Maybe<(
    { __typename?: 'SendCloudParcelItem' }
    & Pick<SendCloudParcelItem, 'description' | 'hs_code' | 'origin_country' | 'product_id' | 'properties' | 'quantity' | 'sku' | 'value' | 'weight'>
  )>>> }
);

export type ShipStationCancelOrderMutationVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type ShipStationCancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelOrder?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type ShipStationDeleteOrderMutationVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type ShipStationDeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type ShipStationHoldOrderMutationVariables = Exact<{
  orderId: Scalars['Float'];
  holdUntilDate: Scalars['String'];
}>;


export type ShipStationHoldOrderMutation = (
  { __typename?: 'Mutation' }
  & { holdOrder?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type ShipStationRestoreOrderMutationVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type ShipStationRestoreOrderMutation = (
  { __typename?: 'Mutation' }
  & { restoreOrderFromHold?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type ShipStationVoidLabelMutationVariables = Exact<{
  orderId: Scalars['Float'];
  shipmentId: Scalars['Float'];
}>;


export type ShipStationVoidLabelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'voidLabel'>
);

export type ShipStationCalculateRatesMutationVariables = Exact<{
  input: CalculateRatesInput;
}>;


export type ShipStationCalculateRatesMutation = (
  { __typename?: 'Mutation' }
  & { calculateRates?: Maybe<Array<Maybe<(
    { __typename?: 'ShipStationRate' }
    & Pick<ShipStationRate, 'serviceName' | 'serviceCode' | 'shipmentCost' | 'otherCost'>
  )>>> }
);

export type ShipStationMarkOrderAsShippedMutationVariables = Exact<{
  input: MarkShippedInput;
}>;


export type ShipStationMarkOrderAsShippedMutation = (
  { __typename?: 'Mutation' }
  & { markOrderAsShipped?: Maybe<(
    { __typename?: 'MarkedShipped' }
    & Pick<MarkedShipped, 'orderId' | 'carrierCode' | 'shipDate' | 'trackingNumber' | 'notifyCustomer' | 'notifySalesChannel'>
  )> }
);

export type ShipStationCreateLabelForOrderMutationVariables = Exact<{
  input: CreateLabelInput;
}>;


export type ShipStationCreateLabelForOrderMutation = (
  { __typename?: 'Mutation' }
  & { createLabelForOrder?: Maybe<(
    { __typename?: 'ShipStationLabel' }
    & Pick<ShipStationLabel, 'shipmentId' | 'shipmentCost' | 'insuranceCost' | 'trackingNumber' | 'labelData' | 'formData'>
  )> }
);

export type ShipStationUpdateOrderAddressMutationVariables = Exact<{
  orderId: Scalars['Float'];
  input: OrderAddressInput;
}>;


export type ShipStationUpdateOrderAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderAddress?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type ShipStationUpdateOrderItemsMutationVariables = Exact<{
  orderId: Scalars['Float'];
  items: Array<OrderItemInput>;
}>;


export type ShipStationUpdateOrderItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderItems?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type ShipStationUpdateOrderNotesMutationVariables = Exact<{
  orderId: Scalars['Float'];
  input: OrderNotesInput;
}>;


export type ShipStationUpdateOrderNotesMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderNotes?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type CreateSendCloudLabelsForOrderMutationVariables = Exact<{
  orderNumbers: Array<Scalars['String']>;
}>;


export type CreateSendCloudLabelsForOrderMutation = (
  { __typename?: 'Mutation' }
  & { createSendCloudLabelsForOrder?: Maybe<Array<Maybe<(
    { __typename?: 'SendCloudLabelWithParcel' }
    & Pick<SendCloudLabelWithParcel, 'error' | 'parcelId' | 'normal_printer' | 'label_printer'>
    & { parcel?: Maybe<(
      { __typename?: 'SendCloudParcel' }
      & Pick<SendCloudParcel, 'name' | 'order_number' | 'tracking_number'>
      & { country?: Maybe<(
        { __typename?: 'SendCloudCountry' }
        & Pick<SendCloudCountry, 'name'>
      )> }
    )> }
  )>>> }
);

export type BatchCreateSendCloudLabelsForOrderMutationVariables = Exact<{
  orderNumbers: Array<Scalars['String']>;
}>;


export type BatchCreateSendCloudLabelsForOrderMutation = (
  { __typename?: 'Mutation' }
  & { batchCreateSendCloudLabelsForOrder?: Maybe<(
    { __typename?: 'BatchSendCloudLabelWithParcel' }
    & Pick<BatchSendCloudLabelWithParcel, 'batch'>
    & { labels?: Maybe<Array<Maybe<(
      { __typename?: 'SendCloudLabelWithParcel' }
      & Pick<SendCloudLabelWithParcel, 'error' | 'parcelId' | 'normal_printer' | 'label_printer'>
      & { parcel?: Maybe<(
        { __typename?: 'SendCloudParcel' }
        & Pick<SendCloudParcel, 'name' | 'order_number' | 'tracking_number'>
        & { country?: Maybe<(
          { __typename?: 'SendCloudCountry' }
          & Pick<SendCloudCountry, 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type SendToSendCloudMutationVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type SendToSendCloudMutation = (
  { __typename?: 'Mutation' }
  & { sendToSendCloud?: Maybe<(
    { __typename?: 'SendToSendCloudResult' }
    & Pick<SendToSendCloudResult, 'orderId'>
    & { order?: Maybe<(
      { __typename?: 'ShipStationOrder' }
      & ShipStationOrderFragment
    )>, parcel?: Maybe<(
      { __typename?: 'SendCloudParcel' }
      & SendCloudParcelFragment
    )> }
  )> }
);

export type ShipStationGetOrderQueryVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type ShipStationGetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder?: Maybe<(
    { __typename?: 'ShipStationOrder' }
    & ShipStationOrderFragment
  )> }
);

export type SendCloudGetParcelQueryVariables = Exact<{
  parcelId?: Maybe<Scalars['Float']>;
  trackingNumber?: Maybe<Scalars['String']>;
}>;


export type SendCloudGetParcelQuery = (
  { __typename?: 'Query' }
  & { getParcel?: Maybe<(
    { __typename?: 'SendCloudParcel' }
    & SendCloudParcelFragment
  )> }
);

export type ShipStationListOrdersQueryVariables = Exact<{
  filter?: Maybe<ShipStationOrderFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
}>;


export type ShipStationListOrdersQuery = (
  { __typename?: 'Query' }
  & { listOrders?: Maybe<(
    { __typename?: 'ShipStationOrderPage' }
    & Pick<ShipStationOrderPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationOrder' }
      & ShipStationOrderFragment
    )>>> }
  )> }
);

export type ShipStationListOrdersByTagQueryVariables = Exact<{
  filter: ShipStationOrderByTagFilter;
  pagination?: Maybe<Pagination>;
}>;


export type ShipStationListOrdersByTagQuery = (
  { __typename?: 'Query' }
  & { listOrdersByTag?: Maybe<(
    { __typename?: 'ShipStationOrderPage' }
    & Pick<ShipStationOrderPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationOrder' }
      & ShipStationOrderFragment
    )>>> }
  )> }
);

export type ShipStationListShipmentsQueryVariables = Exact<{
  filter?: Maybe<ShipStationShipmentsFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
}>;


export type ShipStationListShipmentsQuery = (
  { __typename?: 'Query' }
  & { listShipments?: Maybe<(
    { __typename?: 'ShipStationShipmentPage' }
    & Pick<ShipStationShipmentPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationShipment' }
      & ShipStationShipmentFragment
    )>>> }
  )> }
);

export type ShipStationListFulfillmentsQueryVariables = Exact<{
  filter?: Maybe<ShipStationFulfillmentsFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
}>;


export type ShipStationListFulfillmentsQuery = (
  { __typename?: 'Query' }
  & { listFulfillments?: Maybe<(
    { __typename?: 'ShipStationFulfillmentPage' }
    & Pick<ShipStationFulfillmentPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationFulfillment' }
      & ShipStationFulfillmentFragment
    )>>> }
  )> }
);

export type ShipStationListProductsQueryVariables = Exact<{
  filter?: Maybe<ShipStationProductFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
}>;


export type ShipStationListProductsQuery = (
  { __typename?: 'Query' }
  & { listProducts?: Maybe<(
    { __typename?: 'ShipStationProductPage' }
    & Pick<ShipStationProductPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationProduct' }
      & ShipStationProductFragment
    )>>> }
  )> }
);

export type ShipStationListTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipStationListTagsQuery = (
  { __typename?: 'Query' }
  & { listTags?: Maybe<(
    { __typename?: 'ShipStationTagPage' }
    & Pick<ShipStationTagPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationTag' }
      & Pick<ShipStationTag, 'id' | 'tagId' | 'name' | 'color'>
    )>>> }
  )> }
);

export type ShipStationListStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipStationListStoresQuery = (
  { __typename?: 'Query' }
  & { listStores?: Maybe<(
    { __typename?: 'ShipStationStorePage' }
    & Pick<ShipStationStorePage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationStore' }
      & ShipStationStoreFragment
    )>>> }
  )> }
);

export type ShipStationListCarriersQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipStationListCarriersQuery = (
  { __typename?: 'Query' }
  & { listCarriers?: Maybe<(
    { __typename?: 'ShipStationCarrierPage' }
    & Pick<ShipStationCarrierPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationCarrier' }
      & ShipStationCarrierFragment
    )>>> }
  )> }
);

export type ShipStationGetCarrierQueryVariables = Exact<{
  carrierCode: Scalars['String'];
}>;


export type ShipStationGetCarrierQuery = (
  { __typename?: 'Query' }
  & { getCarrier?: Maybe<(
    { __typename?: 'ShipStationCarrier' }
    & { packages?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationCarrierPackage' }
      & ShipStationCarrierPackageFragment
    )>>>, services?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationCarrierService' }
      & ShipStationCarrierServiceFragment
    )>>> }
    & ShipStationCarrierFragment
  )> }
);

export type ShipStationListWarehousesQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipStationListWarehousesQuery = (
  { __typename?: 'Query' }
  & { listWarehouses?: Maybe<(
    { __typename?: 'ShipStationWarehousePage' }
    & Pick<ShipStationWarehousePage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipStationWarehouse' }
      & ShipStationWarehouseFragment
    )>>> }
  )> }
);

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'firstName' | 'lastName' | 'country' | 'address'>
  & { emails?: Maybe<Array<Maybe<(
    { __typename?: 'Email' }
    & Pick<Email, 'value'>
  )>>> }
);

export type UserInfoFragment = (
  { __typename?: 'UserInfo' }
  & Pick<UserInfo, 'lastMaphash' | 'xyoRedeemed' | 'xyoPending' | 'xyoRedeemPending' | 'xyoDropped' | 'xyoCollected' | 'xyoGifted' | 'xyoGeomined' | 'xyoSurveys' | 'xyoInbrainRevoked' | 'xyoPickedUp' | 'xyoGeoclaimed' | 'xyoHodlRewards' | 'xyoBossBalance' | 'xyoBossGifted' | 'xyoExpired' | 'xyoRestored' | 'xyoSweepstakes' | 'xyoEnabled' | 'hodlLastCollectedYYYYMMDD' | 'recordVersion' | 'remineMarker' | 'recentCount' | 'color' | 'referrer' | 'checkInDate' | 'created' | 'appVersion' | 'nfcScanCount' | 'referrerUid' | 'addedToDrip' | 'mineCountNoSentinel' | 'ibeacons' | 'lastMinedWithSentinelBle' | 'remineCount' | 'subscriptionInfo' | 'osDevMode' | 'ownerUID' | 'osVersion' | 'mineCount' | 'recentMarker' | 'mineCountSentinel' | 'direction' | 'activeSubscription' | 'activeSubscriptionLock' | 'subActive' | 'suspended' | 'ip' | 'lastMinedYYYYMMDD' | 'verifiedAddressesTotal' | 'sentinels' | 'random' | 'liteOk' | 'masterOk' | 'kycComplete' | 'username' | 'ethAddress' | 'lastKnownAddress' | 'coinbaseEmail' | 'shipStreetAddress' | 'webDropsAllowed' | 'webDropsAmountMax' | 'webDropsQuantityMax' | 'lastMined' | 'profileImageExists' | 'profileImageUpdated' | 'subOrder' | 'customerId' | 'lastSync' | 'ssid' | 'os' | 'deviceId' | 'deviceName' | 'speed' | 'appsflyerId' | 'didReceiveReturningUserGift' | 'phoneNumber'>
);

export type CustomerFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'email' | 'paymentCount'>
  & { defaultPaymentInstrument?: Maybe<(
    { __typename?: 'PaymentInstrumentId' }
    & Pick<PaymentInstrumentId, 'paymentCardId'>
  )>, primaryAddress?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )> }
);

export type RebillyTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'amount' | 'status' | 'result' | 'createdTime' | 'gateway' | 'currency'>
);

export type RebillySubscriptionFragment = (
  { __typename?: 'RebillySubscription' }
  & Pick<RebillySubscription, 'id' | 'planType' | 'planId' | 'status' | 'billingStatus' | 'activationTime' | 'canceledTime' | 'renewalTime' | 'startTime'>
  & { upcomingInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFragment
  )>, recentInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFragment
  )>, initialInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFragment
  )> }
);

export type CardFragment = (
  { __typename?: 'PaymentCard' }
  & Pick<PaymentCard, 'id' | 'bankCountry' | 'last4' | 'expYear' | 'expMonth' | 'brand' | 'status'>
);

export type KycFragment = (
  { __typename?: 'KycDocument' }
  & Pick<KycDocument, 'id' | 'fileId' | 'customerId' | 'documentType' | 'status' | 'createdTime' | 'updatedTime' | 'processedTime'>
  & { rejectionReason?: Maybe<(
    { __typename?: 'RejectionReason' }
    & Pick<RejectionReason, 'type' | 'message'>
  )>, documentMatches?: Maybe<(
    { __typename?: 'DocumentMatches' }
    & Pick<DocumentMatches, 'score' | 'data'>
  )> }
);

export type KycMaxAttemptsFragment = (
  { __typename?: 'KycMaxAttempts' }
  & Pick<KycMaxAttempts, 'attempts' | 'attemptsToday' | 'maxAttempts' | 'maxAttemptsToday'>
);

export type InvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'dueTime' | 'issuedTime' | 'abandonedTime' | 'voidedTime' | 'paidTime' | 'currency' | 'invoiceNumber' | 'customerId' | 'websiteId' | 'organizationId' | 'subscriptionId' | 'amount' | 'subtotalAmount' | 'shippingAmount' | 'poNumber' | 'notes' | 'discountAmount' | 'discounts' | 'taxAmount' | 'taxes' | 'autopayScheduledTime' | 'autopayRetryNumber' | 'status' | 'delinquentCollectionPeriod' | 'collectionPeriod' | 'createdTime' | 'updatedTime'>
  & { billingAddress?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )>, deliveryAddress?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )>, items?: Maybe<Array<Maybe<(
    { __typename?: 'InvoiceItem' }
    & Pick<InvoiceItem, 'id' | 'type' | 'description' | 'unitPrice'>
  )>>>, transactions?: Maybe<Array<Maybe<(
    { __typename?: 'Transaction' }
    & RebillyTransactionFragment
  )>>> }
);

export type UserInfoPageQueryVariables = Exact<{
  filter?: Maybe<UserInfoFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
}>;


export type UserInfoPageQuery = (
  { __typename?: 'Query' }
  & { userInfoPage?: Maybe<(
    { __typename?: 'UserInfoPage' }
    & Pick<UserInfoPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserInfo' }
      & UserInfoFragment
    )>>> }
  )> }
);

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'uid' | 'email' | 'customerId'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & { mainSubscription?: Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>, subscriptions?: Maybe<Array<Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>>>, paymentCards?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentCard' }
        & CardFragment
      )>>> }
      & CustomerFragment
    )>, userInfo?: Maybe<(
      { __typename?: 'UserInfo' }
      & UserInfoFragment
    )> }
  )> }
);

export type AccountsQueryVariables = Exact<{
  uids?: Maybe<Array<Scalars['String']>>;
  emails?: Maybe<Array<Scalars['String']>>;
}>;


export type AccountsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'uid' | 'email' | 'customerId'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & { mainSubscription?: Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>, subscriptions?: Maybe<Array<Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>>>, paymentCards?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentCard' }
        & CardFragment
      )>>> }
      & CustomerFragment
    )>, userInfo?: Maybe<(
      { __typename?: 'UserInfo' }
      & UserInfoFragment
    )> }
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Customer' }
    & { mainSubscription?: Maybe<(
      { __typename?: 'RebillySubscription' }
      & RebillySubscriptionFragment
    )>, subscriptions?: Maybe<Array<Maybe<(
      { __typename?: 'RebillySubscription' }
      & RebillySubscriptionFragment
    )>>>, paymentCards?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentCard' }
      & CardFragment
    )>>>, userInfo?: Maybe<(
      { __typename?: 'UserInfo' }
      & UserInfoFragment
    )> }
    & CustomerFragment
  )> }
);

export type KycQueryVariables = Exact<{ [key: string]: never; }>;


export type KycQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { kycDocuments?: Maybe<Array<Maybe<(
      { __typename?: 'KycDocument' }
      & KycFragment
    )>>>, kycMaxAttempts?: Maybe<(
      { __typename?: 'KycMaxAttempts' }
      & KycMaxAttemptsFragment
    )> }
  )> }
);

export type KycByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type KycByIdQuery = (
  { __typename?: 'Query' }
  & { kycDocumentById?: Maybe<(
    { __typename?: 'KycDocument' }
    & Pick<KycDocument, 'download'>
    & KycFragment
  )> }
);

export type CreateKycMutationVariables = Exact<{
  input: KycDocumentInput;
}>;


export type CreateKycMutation = (
  { __typename?: 'Mutation' }
  & { createKycDocument?: Maybe<(
    { __typename?: 'KycDocumentResult' }
    & Pick<KycDocumentResult, 'customerId'>
    & { kycDocument?: Maybe<(
      { __typename?: 'KycDocument' }
      & KycFragment
    )>, kycMaxAttempts?: Maybe<(
      { __typename?: 'KycMaxAttempts' }
      & KycMaxAttemptsFragment
    )> }
  )> }
);

export type UpdateKycMutationVariables = Exact<{
  id: Scalars['String'];
  input: KycDocumentInput;
}>;


export type UpdateKycMutation = (
  { __typename?: 'Mutation' }
  & { updateKycDocument?: Maybe<(
    { __typename?: 'KycDocumentResult' }
    & Pick<KycDocumentResult, 'customerId'>
    & { kycDocument?: Maybe<(
      { __typename?: 'KycDocument' }
      & KycFragment
    )>, kycMaxAttempts?: Maybe<(
      { __typename?: 'KycMaxAttempts' }
      & KycMaxAttemptsFragment
    )> }
  )> }
);

export type AcceptKycMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AcceptKycMutation = (
  { __typename?: 'Mutation' }
  & { acceptKycDocument?: Maybe<(
    { __typename?: 'KycDocument' }
    & KycFragment
  )> }
);

export type RejectKycMutationVariables = Exact<{
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
}>;


export type RejectKycMutation = (
  { __typename?: 'Mutation' }
  & { rejectKycDocument?: Maybe<(
    { __typename?: 'KycDocument' }
    & KycFragment
  )> }
);

export type CustomerProfileQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type CustomerProfileQuery = (
  { __typename?: 'Query' }
  & { customerProfileByUid?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'uid' | 'email' | 'photoURL'>
  )> }
);

export type CustomerQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & { mainSubscription?: Maybe<(
      { __typename?: 'RebillySubscription' }
      & RebillySubscriptionFragment
    )>, subscriptions?: Maybe<Array<Maybe<(
      { __typename?: 'RebillySubscription' }
      & RebillySubscriptionFragment
    )>>>, pastSubscriptions?: Maybe<Array<Maybe<(
      { __typename?: 'RebillySubscription' }
      & RebillySubscriptionFragment
    )>>>, paymentCards?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentCard' }
      & CardFragment
    )>>> }
    & CustomerFragment
  )> }
);

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = (
  { __typename?: 'Query' }
  & { plans?: Maybe<Array<Maybe<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id'>
  )>>> }
);

export type InvoiceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFragment
  )> }
);

export type CheckoutMutationVariables = Exact<{
  planId: Scalars['String'];
  paymentCardId: Scalars['String'];
  customer?: Maybe<CustomerInput>;
  shipping?: Maybe<AddressInput>;
  billing?: Maybe<AddressInput>;
}>;


export type CheckoutMutation = (
  { __typename?: 'Mutation' }
  & { checkout: (
    { __typename?: 'CheckoutResponse' }
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'amount' | 'status' | 'result'>
    )>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & { mainSubscription?: Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>, subscriptions?: Maybe<Array<Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>>>, paymentCards?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentCard' }
        & CardFragment
      )>>> }
      & CustomerFragment
    )> }
  ) }
);

export type CheckoutV2MutationVariables = Exact<{
  planId: Scalars['String'];
  paymentCardId: Scalars['String'];
  customer?: Maybe<CustomerInput>;
  shipping?: Maybe<AddressInput>;
  billing?: Maybe<AddressInput>;
}>;


export type CheckoutV2Mutation = (
  { __typename?: 'Mutation' }
  & { checkout: (
    { __typename?: 'CheckoutResponse' }
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'amount' | 'status' | 'result'>
    )>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & { mainSubscription?: Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>, subscriptions?: Maybe<Array<Maybe<(
        { __typename?: 'RebillySubscription' }
        & RebillySubscriptionFragment
      )>>>, paymentCards?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentCard' }
        & CardFragment
      )>>> }
      & CustomerFragment
    )> }
  ) }
);

export type TransactionMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  paymentCardId: Scalars['String'];
}>;


export type TransactionMutation = (
  { __typename?: 'Mutation' }
  & { createTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'status' | 'result'>
  )> }
);

export type TransactionV2MutationVariables = Exact<{
  invoiceId: Scalars['String'];
  paymentCardId: Scalars['String'];
}>;


export type TransactionV2Mutation = (
  { __typename?: 'Mutation' }
  & { createTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'status' | 'result'>
  )> }
);

export type CancelSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelSubscription?: Maybe<(
    { __typename?: 'RebillySubscription' }
    & RebillySubscriptionFragment
  )> }
);

export type ReactivateSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type ReactivateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { reactivateSubscription?: Maybe<(
    { __typename?: 'RebillySubscription' }
    & RebillySubscriptionFragment
  )> }
);

export type ChangeSubscriptionPreviewMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
  planId: Scalars['String'];
}>;


export type ChangeSubscriptionPreviewMutation = (
  { __typename?: 'Mutation' }
  & { changeSubscriptionPreview?: Maybe<(
    { __typename?: 'ChangeSubscription' }
    & Pick<ChangeSubscription, 'status' | 'billingStatus' | 'lineItemSubtotal'>
    & { items: Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'planId'>
    )>, lineItems: Array<(
      { __typename?: 'LineItem' }
      & Pick<LineItem, 'quantity' | 'unitPriceAmount' | 'unitPriceCurrency' | 'description' | 'type'>
    )> }
  )> }
);

export type ChangeSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
  planId: Scalars['String'];
  paymentCardId: Scalars['String'];
}>;


export type ChangeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { changeSubscription?: Maybe<(
    { __typename?: 'RebillySubscription' }
    & RebillySubscriptionFragment
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFragment
  )> }
);

export type AddPaymentMutationVariables = Exact<{
  paymentToken: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerInput>;
}>;


export type AddPaymentMutation = (
  { __typename?: 'Mutation' }
  & { addPayment?: Maybe<(
    { __typename?: 'Customer' }
    & { subscriptions?: Maybe<Array<Maybe<(
      { __typename?: 'RebillySubscription' }
      & RebillySubscriptionFragment
    )>>>, paymentCards?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentCard' }
      & CardFragment
    )>>> }
    & CustomerFragment
  )> }
);

export type CumulativeSubscriptionCountFragment = (
  { __typename?: 'CumulativeSubscriptionCount' }
  & Pick<CumulativeSubscriptionCount, 'day' | 'planId' | 'cumulativeCount' | 'newCount' | 'canceledCount' | 'netCount'>
);

export type CumulativeTransactionCountFragment = (
  { __typename?: 'CumulativeTransactionCount' }
  & Pick<CumulativeTransactionCount, 'aggregationFieldValue' | 'authApprovedThroughput' | 'authApprovalCount' | 'salesAverage' | 'salesCount' | 'salesValue' | 'disputesRate' | 'disputesCount' | 'refundsCount' | 'refundsValue' | 'amount' | 'count'>
);

export type CumulativeSubscriptionCountQueryVariables = Exact<{
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
}>;


export type CumulativeSubscriptionCountQuery = (
  { __typename?: 'Query' }
  & { cumulativeSubscriptionCount?: Maybe<(
    { __typename?: 'CumulativeSubscriptionCountResult' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CumulativeSubscriptionCount' }
      & CumulativeSubscriptionCountFragment
    )>>> }
  )> }
);

export type CumulativeTransactionCountQueryVariables = Exact<{
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
}>;


export type CumulativeTransactionCountQuery = (
  { __typename?: 'Query' }
  & { cumulativeTransactionCount?: Maybe<(
    { __typename?: 'CumulativeTransactionCountResult' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CumulativeTransactionCount' }
      & CumulativeTransactionCountFragment
    )>>> }
  )> }
);

export type CumulativeStatsQueryVariables = Exact<{
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
}>;


export type CumulativeStatsQuery = (
  { __typename?: 'Query' }
  & { cumulativeSubscriptionCount?: Maybe<(
    { __typename?: 'CumulativeSubscriptionCountResult' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CumulativeSubscriptionCount' }
      & CumulativeSubscriptionCountFragment
    )>>> }
  )>, cumulativeTransactionCount?: Maybe<(
    { __typename?: 'CumulativeTransactionCountResult' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CumulativeTransactionCount' }
      & CumulativeTransactionCountFragment
    )>>> }
  )> }
);

export type DeactivatePaymentCardMutationVariables = Exact<{
  paymentCardId: Scalars['String'];
}>;


export type DeactivatePaymentCardMutation = (
  { __typename?: 'Mutation' }
  & { deactivatePaymentCard?: Maybe<(
    { __typename?: 'PaymentCard' }
    & CardFragment
  )> }
);

export type RefundRequestFragment = (
  { __typename?: 'RefundRequest' }
  & Pick<RefundRequest, 'id' | 'url' | 'uid' | 'email' | 'photoURL' | 'date' | 'expireAt' | 'refunded' | 'refunding' | 'accepted' | 'active' | 'error' | 'startTime' | 'activeSubscription' | 'iosSub' | 'iosErr' | 'androidSub' | 'androidErr' | 'rebillyErr' | 'eligibleMessage' | 'eligibleMonths' | 'eligibleAmount' | 'collectedAmount' | 'collectedAmountRange' | 'collectedCount' | 'droppedAmount' | 'dropCount' | 'redeemCount' | 'xyoCollected' | 'resetXyoCollectedAmount'>
  & { rebillySub?: Maybe<(
    { __typename?: 'RebillySub' }
    & Pick<RebillySub, 'subscriptionId'>
    & { transactions?: Maybe<Array<(
      { __typename?: 'RebillyTrans' }
      & Pick<RebillyTrans, 'createdTime' | 'transactionId' | 'amount'>
    )>> }
  )> }
);

export type RefundRequestQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RefundRequestQuery = (
  { __typename?: 'Query' }
  & { refundRequest?: Maybe<(
    { __typename?: 'RefundRequest' }
    & RefundRequestFragment
  )> }
);

export type RefundRequestsQueryVariables = Exact<{
  filter?: Maybe<RefundRequestFilter>;
  pagination?: Maybe<Pagination>;
  sort?: Maybe<Sort>;
}>;


export type RefundRequestsQuery = (
  { __typename?: 'Query' }
  & { refundRequests?: Maybe<(
    { __typename?: 'RefundRequestPage' }
    & Pick<RefundRequestPage, 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'RefundRequest' }
      & RefundRequestFragment
    )>>> }
  )> }
);

export type GenerateRefundRequestMutationVariables = Exact<{
  uid: Scalars['String'];
  expireSeconds?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
}>;


export type GenerateRefundRequestMutation = (
  { __typename?: 'Mutation' }
  & { generateRefundRequest?: Maybe<(
    { __typename?: 'RefundRequest' }
    & RefundRequestFragment
  )> }
);

export type ProcessRefundRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProcessRefundRequestMutation = (
  { __typename?: 'Mutation' }
  & { processRefundRequest?: Maybe<(
    { __typename?: 'RefundRequest' }
    & RefundRequestFragment
  )> }
);

export type ProcessedRefundRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProcessedRefundRequestMutation = (
  { __typename?: 'Mutation' }
  & { processedRefundRequest?: Maybe<(
    { __typename?: 'RefundRequest' }
    & RefundRequestFragment
  )> }
);

export type DeactivateRefundRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeactivateRefundRequestMutation = (
  { __typename?: 'Mutation' }
  & { deactivateRefundRequest?: Maybe<(
    { __typename?: 'RefundRequest' }
    & RefundRequestFragment
  )> }
);

export type ActivateRefundRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ActivateRefundRequestMutation = (
  { __typename?: 'Mutation' }
  & { activateRefundRequest?: Maybe<(
    { __typename?: 'RefundRequest' }
    & RefundRequestFragment
  )> }
);

export const ShipStationAddressFragmentDoc = gql`
    fragment ShipStationAddress on ShipStationAddress {
  name
  company
  street1
  street2
  street3
  city
  state
  postalCode
  country
  phone
  residential
  addressVerified
}
    `;
export const ShipStationOrderItemFragmentDoc = gql`
    fragment ShipStationOrderItem on ShipStationOrderItem {
  orderItemId
  lineItemKey
  sku
  name
  imageUrl
  weight {
    value
    units
  }
  quantity
  unitPrice
  taxAmount
  shippingAmount
  warehouseLocation
  options {
    name
    value
  }
  productId
  fulfillmentSku
  adjustment
  upc
  createDate
  modifyDate
}
    `;
export const ShipStationInsuranceOptionsFragmentDoc = gql`
    fragment ShipStationInsuranceOptions on ShipStationInsuranceOptions {
  provider
  insureShipment
  insuredValue
}
    `;
export const ShipStationInternationalOptionsFragmentDoc = gql`
    fragment ShipStationInternationalOptions on ShipStationInternationalOptions {
  contents
  customsItems {
    customsItemId
    description
    quantity
    value
    harmonizedTariffCode
    countryOfOrigin
  }
  nonDelivery
}
    `;
export const ShipStationAdvancedOptionsFragmentDoc = gql`
    fragment ShipStationAdvancedOptions on ShipStationAdvancedOptions {
  warehouseId
  nonMachinable
  saturdayDelivery
  containsAlcohol
  storeId
  customField1
  customField2
  customField3
  source
  mergedOrSplit
  mergedIds
  parentId
  billToParty
  billToAccount
  billToPostalCode
  billToCountryCode
  billToMyOtherAccount
}
    `;
export const ShipStationOrderFragmentDoc = gql`
    fragment ShipStationOrder on ShipStationOrder {
  id
  orderId
  orderNumber
  orderKey
  orderDate
  createDate
  modifyDate
  paymentDate
  shipByDate
  orderStatus
  customerId
  customerUsername
  customerEmail
  billTo {
    ...ShipStationAddress
  }
  shipTo {
    ...ShipStationAddress
  }
  items {
    ...ShipStationOrderItem
  }
  orderTotal
  amountPaid
  taxAmount
  shippingAmount
  customerNotes
  internalNotes
  gift
  giftMessage
  paymentMethod
  requestedShippingService
  carrierCode
  serviceCode
  packageCode
  confirmation
  shipDate
  holdUntilDate
  weight {
    value
    units
  }
  dimensions {
    length
    width
    height
    units
  }
  insuranceOptions {
    ...ShipStationInsuranceOptions
  }
  internationalOptions {
    ...ShipStationInternationalOptions
  }
  advancedOptions {
    ...ShipStationAdvancedOptions
  }
  tagIds
  userId
  externallyFulfilled
  externallyFulfilledBy
}
    ${ShipStationAddressFragmentDoc}
${ShipStationOrderItemFragmentDoc}
${ShipStationInsuranceOptionsFragmentDoc}
${ShipStationInternationalOptionsFragmentDoc}
${ShipStationAdvancedOptionsFragmentDoc}`;
export const ShipStationShipmentFragmentDoc = gql`
    fragment ShipStationShipment on ShipStationShipment {
  id
  shipmentId
  orderId
  orderKey
  userId
  orderNumber
  createDate
  shipDate
  shipmentCost
  insuranceCost
  trackingNumber
  isReturnLabel
  customerEmail
  batchNumber
  carrierCode
  serviceCode
  packageCode
  confirmation
  warehouseId
  voided
  voidDate
  marketplaceNotified
  notifyErrorMessage
  shipTo {
    ...ShipStationAddress
  }
  weight {
    value
    units
  }
  dimensions {
    length
    width
    height
    units
  }
  insuranceOptions {
    ...ShipStationInsuranceOptions
  }
  advancedOptions {
    ...ShipStationAdvancedOptions
  }
  shipmentItems {
    ...ShipStationOrderItem
  }
  labelData
  formData
}
    ${ShipStationAddressFragmentDoc}
${ShipStationInsuranceOptionsFragmentDoc}
${ShipStationAdvancedOptionsFragmentDoc}
${ShipStationOrderItemFragmentDoc}`;
export const ShipStationProductFragmentDoc = gql`
    fragment ShipStationProduct on ShipStationProduct {
  id
  productId
  sku
  name
  price
  defaultCost
  length
  width
  height
  weightOz
  internalNotes
  fulfillmentSku
  createDate
  modifyDate
  active
  productCategory {
    categoryId
    name
  }
  productType
  warehouseLocation
  defaultCarrierCode
  defaultServiceCode
  defaultPackageCode
  defaultIntlCarrierCode
  defaultIntlServiceCode
  defaultIntlPackageCode
  defaultConfirmation
  defaultIntlConfirmation
  customsDescription
  customsValue
  customsTariffNo
  customsCountryCode
  noCustoms
  tags {
    tagId
    name
  }
}
    `;
export const ShipStationFulfillmentFragmentDoc = gql`
    fragment ShipStationFulfillment on ShipStationFulfillment {
  id
  fulfillmentId
  orderId
  orderNumber
  userId
  customerEmail
  trackingNumber
  createDate
  shipDate
  voidDate
  deliveryDate
  carrierCode
  fulfillmentProviderCode
  fulfillmentServiceCode
  fulfillmentFee
  voidRequested
  voided
  marketplaceNotified
  notifyErrorMessage
  shipTo {
    ...ShipStationAddress
  }
}
    ${ShipStationAddressFragmentDoc}`;
export const ShipStationCarrierFragmentDoc = gql`
    fragment ShipStationCarrier on ShipStationCarrier {
  id
  name
  code
  accountNumber
  requiresFundedAccount
  balance
  nickname
  shippingProviderId
  primary
}
    `;
export const ShipStationCarrierPackageFragmentDoc = gql`
    fragment ShipStationCarrierPackage on ShipStationCarrierPackage {
  carrierCode
  code
  name
  domestic
  international
}
    `;
export const ShipStationCarrierServiceFragmentDoc = gql`
    fragment ShipStationCarrierService on ShipStationCarrierService {
  carrierCode
  code
  name
  domestic
  international
}
    `;
export const ShipStationStoreFragmentDoc = gql`
    fragment ShipStationStore on ShipStationStore {
  id
  storeId
  storeName
  marketplaceId
  marketplaceName
  accountName
  email
  integrationUrl
  active
  companyName
  phone
  publicEmail
  website
  refreshDate
  lastRefreshAttempt
  createDate
  modifyDate
  autoRefresh
}
    `;
export const ShipStationWarehouseFragmentDoc = gql`
    fragment ShipStationWarehouse on ShipStationWarehouse {
  id
  warehouseId
  warehouseName
  originAddress {
    ...ShipStationAddress
  }
  returnAddress {
    ...ShipStationAddress
  }
  createDate
  isDefault
}
    ${ShipStationAddressFragmentDoc}`;
export const SendCloudParcelFragmentDoc = gql`
    fragment SendCloudParcel on SendCloudParcel {
  id
  name
  company_name
  address
  address2
  address_divided {
    street
    house_number
  }
  city
  postal_code
  telephone
  email
  date_created
  tracking_number
  tracking_url
  weight
  label {
    normal_printer
    label_printer
  }
  customs_declaration
  status {
    id
    message
  }
  data
  country {
    iso_3
    iso_2
    name
  }
  shipment {
    id
    name
  }
  order_number
  shipment_uuid
  external_order_id
  external_shipment_id
  external_reference
  parcel_items {
    description
    hs_code
    origin_country
    product_id
    properties
    quantity
    sku
    value
    weight
  }
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on UserInfo {
  lastMaphash
  xyoRedeemed
  xyoPending
  xyoRedeemPending
  xyoDropped
  xyoCollected
  xyoGifted
  xyoGeomined
  xyoSurveys
  xyoInbrainRevoked
  xyoPickedUp
  xyoGeoclaimed
  xyoHodlRewards
  xyoBossBalance
  xyoBossGifted
  xyoExpired
  xyoRestored
  xyoSweepstakes
  xyoEnabled
  hodlLastCollectedYYYYMMDD
  recordVersion
  remineMarker
  recentCount
  color
  referrer
  checkInDate
  created
  appVersion
  nfcScanCount
  referrerUid
  addedToDrip
  mineCountNoSentinel
  ibeacons
  lastMinedWithSentinelBle
  remineCount
  subscriptionInfo
  osDevMode
  ownerUID
  osVersion
  mineCount
  recentMarker
  mineCountSentinel
  direction
  activeSubscription
  activeSubscriptionLock
  subActive
  suspended
  ip
  lastMinedYYYYMMDD
  verifiedAddressesTotal
  sentinels
  random
  liteOk
  masterOk
  kycComplete
  username
  ethAddress
  lastKnownAddress
  coinbaseEmail
  shipStreetAddress
  webDropsAllowed
  webDropsAmountMax
  webDropsQuantityMax
  lastMined
  profileImageExists
  profileImageUpdated
  subOrder
  customerId
  lastSync
  ssid
  os
  deviceId
  deviceName
  speed
  appsflyerId
  didReceiveReturningUserGift
  phoneNumber
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  firstName
  lastName
  country
  address
  emails {
    value
  }
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  email
  defaultPaymentInstrument {
    paymentCardId
  }
  paymentCount
  primaryAddress {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export const RebillyTransactionFragmentDoc = gql`
    fragment RebillyTransaction on Transaction {
  id
  amount
  status
  result
  createdTime
  gateway
  currency
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on Invoice {
  id
  dueTime
  issuedTime
  abandonedTime
  voidedTime
  paidTime
  currency
  invoiceNumber
  customerId
  websiteId
  organizationId
  subscriptionId
  billingAddress {
    ...Address
  }
  deliveryAddress {
    ...Address
  }
  amount
  currency
  subtotalAmount
  shippingAmount
  poNumber
  notes
  items {
    id
    type
    description
    unitPrice
  }
  discountAmount
  discounts
  taxAmount
  taxes
  transactions {
    ...RebillyTransaction
  }
  autopayScheduledTime
  autopayRetryNumber
  status
  delinquentCollectionPeriod
  collectionPeriod
  createdTime
  updatedTime
}
    ${AddressFragmentDoc}
${RebillyTransactionFragmentDoc}`;
export const RebillySubscriptionFragmentDoc = gql`
    fragment RebillySubscription on RebillySubscription {
  id
  planType
  planId
  status
  billingStatus
  activationTime
  canceledTime
  renewalTime
  startTime
  upcomingInvoice {
    ...Invoice
  }
  recentInvoice {
    ...Invoice
  }
  initialInvoice {
    ...Invoice
  }
}
    ${InvoiceFragmentDoc}`;
export const CardFragmentDoc = gql`
    fragment Card on PaymentCard {
  id
  bankCountry
  last4
  expYear
  expMonth
  brand
  status
}
    `;
export const KycFragmentDoc = gql`
    fragment Kyc on KycDocument {
  id
  fileId
  customerId
  documentType
  status
  createdTime
  updatedTime
  processedTime
  rejectionReason {
    type
    message
  }
  documentMatches {
    score
    data
  }
}
    `;
export const KycMaxAttemptsFragmentDoc = gql`
    fragment KycMaxAttempts on KycMaxAttempts {
  attempts
  attemptsToday
  maxAttempts
  maxAttemptsToday
}
    `;
export const CumulativeSubscriptionCountFragmentDoc = gql`
    fragment CumulativeSubscriptionCount on CumulativeSubscriptionCount {
  day
  planId
  cumulativeCount
  newCount
  canceledCount
  netCount
}
    `;
export const CumulativeTransactionCountFragmentDoc = gql`
    fragment CumulativeTransactionCount on CumulativeTransactionCount {
  aggregationFieldValue
  authApprovedThroughput
  authApprovalCount
  salesAverage
  salesCount
  salesValue
  disputesRate
  disputesCount
  refundsCount
  refundsValue
  amount
  count
}
    `;
export const RefundRequestFragmentDoc = gql`
    fragment RefundRequest on RefundRequest {
  id
  url
  uid
  email
  photoURL
  date
  expireAt
  refunded
  refunding
  accepted
  active
  error
  startTime
  activeSubscription
  iosSub
  iosErr
  androidSub
  androidErr
  rebillySub {
    subscriptionId
    transactions {
      createdTime
      transactionId
      amount
    }
  }
  rebillyErr
  eligibleMessage
  eligibleMonths
  eligibleAmount
  collectedAmount
  collectedAmountRange
  collectedCount
  droppedAmount
  dropCount
  redeemCount
  xyoCollected
  resetXyoCollectedAmount
}
    `;
export const ShipStationCancelOrderDocument = gql`
    mutation ShipStationCancelOrder($orderId: Float!) {
  cancelOrder(orderId: $orderId) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationCancelOrderMutationFn = Apollo.MutationFunction<ShipStationCancelOrderMutation, ShipStationCancelOrderMutationVariables>;

/**
 * __useShipStationCancelOrderMutation__
 *
 * To run a mutation, you first call `useShipStationCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationCancelOrderMutation, { data, loading, error }] = useShipStationCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useShipStationCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationCancelOrderMutation, ShipStationCancelOrderMutationVariables>) {
        return Apollo.useMutation<ShipStationCancelOrderMutation, ShipStationCancelOrderMutationVariables>(ShipStationCancelOrderDocument, baseOptions);
      }
export type ShipStationCancelOrderMutationHookResult = ReturnType<typeof useShipStationCancelOrderMutation>;
export type ShipStationCancelOrderMutationResult = Apollo.MutationResult<ShipStationCancelOrderMutation>;
export type ShipStationCancelOrderMutationOptions = Apollo.BaseMutationOptions<ShipStationCancelOrderMutation, ShipStationCancelOrderMutationVariables>;
export const ShipStationDeleteOrderDocument = gql`
    mutation ShipStationDeleteOrder($orderId: Float!) {
  deleteOrder(orderId: $orderId) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationDeleteOrderMutationFn = Apollo.MutationFunction<ShipStationDeleteOrderMutation, ShipStationDeleteOrderMutationVariables>;

/**
 * __useShipStationDeleteOrderMutation__
 *
 * To run a mutation, you first call `useShipStationDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationDeleteOrderMutation, { data, loading, error }] = useShipStationDeleteOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useShipStationDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationDeleteOrderMutation, ShipStationDeleteOrderMutationVariables>) {
        return Apollo.useMutation<ShipStationDeleteOrderMutation, ShipStationDeleteOrderMutationVariables>(ShipStationDeleteOrderDocument, baseOptions);
      }
export type ShipStationDeleteOrderMutationHookResult = ReturnType<typeof useShipStationDeleteOrderMutation>;
export type ShipStationDeleteOrderMutationResult = Apollo.MutationResult<ShipStationDeleteOrderMutation>;
export type ShipStationDeleteOrderMutationOptions = Apollo.BaseMutationOptions<ShipStationDeleteOrderMutation, ShipStationDeleteOrderMutationVariables>;
export const ShipStationHoldOrderDocument = gql`
    mutation ShipStationHoldOrder($orderId: Float!, $holdUntilDate: String!) {
  holdOrder(orderId: $orderId, holdUntilDate: $holdUntilDate) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationHoldOrderMutationFn = Apollo.MutationFunction<ShipStationHoldOrderMutation, ShipStationHoldOrderMutationVariables>;

/**
 * __useShipStationHoldOrderMutation__
 *
 * To run a mutation, you first call `useShipStationHoldOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationHoldOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationHoldOrderMutation, { data, loading, error }] = useShipStationHoldOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      holdUntilDate: // value for 'holdUntilDate'
 *   },
 * });
 */
export function useShipStationHoldOrderMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationHoldOrderMutation, ShipStationHoldOrderMutationVariables>) {
        return Apollo.useMutation<ShipStationHoldOrderMutation, ShipStationHoldOrderMutationVariables>(ShipStationHoldOrderDocument, baseOptions);
      }
export type ShipStationHoldOrderMutationHookResult = ReturnType<typeof useShipStationHoldOrderMutation>;
export type ShipStationHoldOrderMutationResult = Apollo.MutationResult<ShipStationHoldOrderMutation>;
export type ShipStationHoldOrderMutationOptions = Apollo.BaseMutationOptions<ShipStationHoldOrderMutation, ShipStationHoldOrderMutationVariables>;
export const ShipStationRestoreOrderDocument = gql`
    mutation ShipStationRestoreOrder($orderId: Float!) {
  restoreOrderFromHold(orderId: $orderId) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationRestoreOrderMutationFn = Apollo.MutationFunction<ShipStationRestoreOrderMutation, ShipStationRestoreOrderMutationVariables>;

/**
 * __useShipStationRestoreOrderMutation__
 *
 * To run a mutation, you first call `useShipStationRestoreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationRestoreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationRestoreOrderMutation, { data, loading, error }] = useShipStationRestoreOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useShipStationRestoreOrderMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationRestoreOrderMutation, ShipStationRestoreOrderMutationVariables>) {
        return Apollo.useMutation<ShipStationRestoreOrderMutation, ShipStationRestoreOrderMutationVariables>(ShipStationRestoreOrderDocument, baseOptions);
      }
export type ShipStationRestoreOrderMutationHookResult = ReturnType<typeof useShipStationRestoreOrderMutation>;
export type ShipStationRestoreOrderMutationResult = Apollo.MutationResult<ShipStationRestoreOrderMutation>;
export type ShipStationRestoreOrderMutationOptions = Apollo.BaseMutationOptions<ShipStationRestoreOrderMutation, ShipStationRestoreOrderMutationVariables>;
export const ShipStationVoidLabelDocument = gql`
    mutation ShipStationVoidLabel($orderId: Float!, $shipmentId: Float!) {
  voidLabel(orderId: $orderId, shipmentId: $shipmentId)
}
    `;
export type ShipStationVoidLabelMutationFn = Apollo.MutationFunction<ShipStationVoidLabelMutation, ShipStationVoidLabelMutationVariables>;

/**
 * __useShipStationVoidLabelMutation__
 *
 * To run a mutation, you first call `useShipStationVoidLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationVoidLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationVoidLabelMutation, { data, loading, error }] = useShipStationVoidLabelMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      shipmentId: // value for 'shipmentId'
 *   },
 * });
 */
export function useShipStationVoidLabelMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationVoidLabelMutation, ShipStationVoidLabelMutationVariables>) {
        return Apollo.useMutation<ShipStationVoidLabelMutation, ShipStationVoidLabelMutationVariables>(ShipStationVoidLabelDocument, baseOptions);
      }
export type ShipStationVoidLabelMutationHookResult = ReturnType<typeof useShipStationVoidLabelMutation>;
export type ShipStationVoidLabelMutationResult = Apollo.MutationResult<ShipStationVoidLabelMutation>;
export type ShipStationVoidLabelMutationOptions = Apollo.BaseMutationOptions<ShipStationVoidLabelMutation, ShipStationVoidLabelMutationVariables>;
export const ShipStationCalculateRatesDocument = gql`
    mutation ShipStationCalculateRates($input: CalculateRatesInput!) {
  calculateRates(input: $input) {
    serviceName
    serviceCode
    shipmentCost
    otherCost
  }
}
    `;
export type ShipStationCalculateRatesMutationFn = Apollo.MutationFunction<ShipStationCalculateRatesMutation, ShipStationCalculateRatesMutationVariables>;

/**
 * __useShipStationCalculateRatesMutation__
 *
 * To run a mutation, you first call `useShipStationCalculateRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationCalculateRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationCalculateRatesMutation, { data, loading, error }] = useShipStationCalculateRatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipStationCalculateRatesMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationCalculateRatesMutation, ShipStationCalculateRatesMutationVariables>) {
        return Apollo.useMutation<ShipStationCalculateRatesMutation, ShipStationCalculateRatesMutationVariables>(ShipStationCalculateRatesDocument, baseOptions);
      }
export type ShipStationCalculateRatesMutationHookResult = ReturnType<typeof useShipStationCalculateRatesMutation>;
export type ShipStationCalculateRatesMutationResult = Apollo.MutationResult<ShipStationCalculateRatesMutation>;
export type ShipStationCalculateRatesMutationOptions = Apollo.BaseMutationOptions<ShipStationCalculateRatesMutation, ShipStationCalculateRatesMutationVariables>;
export const ShipStationMarkOrderAsShippedDocument = gql`
    mutation ShipStationMarkOrderAsShipped($input: MarkShippedInput!) {
  markOrderAsShipped(input: $input) {
    orderId
    carrierCode
    shipDate
    trackingNumber
    notifyCustomer
    notifySalesChannel
  }
}
    `;
export type ShipStationMarkOrderAsShippedMutationFn = Apollo.MutationFunction<ShipStationMarkOrderAsShippedMutation, ShipStationMarkOrderAsShippedMutationVariables>;

/**
 * __useShipStationMarkOrderAsShippedMutation__
 *
 * To run a mutation, you first call `useShipStationMarkOrderAsShippedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationMarkOrderAsShippedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationMarkOrderAsShippedMutation, { data, loading, error }] = useShipStationMarkOrderAsShippedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipStationMarkOrderAsShippedMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationMarkOrderAsShippedMutation, ShipStationMarkOrderAsShippedMutationVariables>) {
        return Apollo.useMutation<ShipStationMarkOrderAsShippedMutation, ShipStationMarkOrderAsShippedMutationVariables>(ShipStationMarkOrderAsShippedDocument, baseOptions);
      }
export type ShipStationMarkOrderAsShippedMutationHookResult = ReturnType<typeof useShipStationMarkOrderAsShippedMutation>;
export type ShipStationMarkOrderAsShippedMutationResult = Apollo.MutationResult<ShipStationMarkOrderAsShippedMutation>;
export type ShipStationMarkOrderAsShippedMutationOptions = Apollo.BaseMutationOptions<ShipStationMarkOrderAsShippedMutation, ShipStationMarkOrderAsShippedMutationVariables>;
export const ShipStationCreateLabelForOrderDocument = gql`
    mutation ShipStationCreateLabelForOrder($input: CreateLabelInput!) {
  createLabelForOrder(input: $input) {
    shipmentId
    shipmentCost
    insuranceCost
    trackingNumber
    labelData
    formData
  }
}
    `;
export type ShipStationCreateLabelForOrderMutationFn = Apollo.MutationFunction<ShipStationCreateLabelForOrderMutation, ShipStationCreateLabelForOrderMutationVariables>;

/**
 * __useShipStationCreateLabelForOrderMutation__
 *
 * To run a mutation, you first call `useShipStationCreateLabelForOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationCreateLabelForOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationCreateLabelForOrderMutation, { data, loading, error }] = useShipStationCreateLabelForOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipStationCreateLabelForOrderMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationCreateLabelForOrderMutation, ShipStationCreateLabelForOrderMutationVariables>) {
        return Apollo.useMutation<ShipStationCreateLabelForOrderMutation, ShipStationCreateLabelForOrderMutationVariables>(ShipStationCreateLabelForOrderDocument, baseOptions);
      }
export type ShipStationCreateLabelForOrderMutationHookResult = ReturnType<typeof useShipStationCreateLabelForOrderMutation>;
export type ShipStationCreateLabelForOrderMutationResult = Apollo.MutationResult<ShipStationCreateLabelForOrderMutation>;
export type ShipStationCreateLabelForOrderMutationOptions = Apollo.BaseMutationOptions<ShipStationCreateLabelForOrderMutation, ShipStationCreateLabelForOrderMutationVariables>;
export const ShipStationUpdateOrderAddressDocument = gql`
    mutation ShipStationUpdateOrderAddress($orderId: Float!, $input: OrderAddressInput!) {
  updateOrderAddress(orderId: $orderId, input: $input) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationUpdateOrderAddressMutationFn = Apollo.MutationFunction<ShipStationUpdateOrderAddressMutation, ShipStationUpdateOrderAddressMutationVariables>;

/**
 * __useShipStationUpdateOrderAddressMutation__
 *
 * To run a mutation, you first call `useShipStationUpdateOrderAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationUpdateOrderAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationUpdateOrderAddressMutation, { data, loading, error }] = useShipStationUpdateOrderAddressMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipStationUpdateOrderAddressMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationUpdateOrderAddressMutation, ShipStationUpdateOrderAddressMutationVariables>) {
        return Apollo.useMutation<ShipStationUpdateOrderAddressMutation, ShipStationUpdateOrderAddressMutationVariables>(ShipStationUpdateOrderAddressDocument, baseOptions);
      }
export type ShipStationUpdateOrderAddressMutationHookResult = ReturnType<typeof useShipStationUpdateOrderAddressMutation>;
export type ShipStationUpdateOrderAddressMutationResult = Apollo.MutationResult<ShipStationUpdateOrderAddressMutation>;
export type ShipStationUpdateOrderAddressMutationOptions = Apollo.BaseMutationOptions<ShipStationUpdateOrderAddressMutation, ShipStationUpdateOrderAddressMutationVariables>;
export const ShipStationUpdateOrderItemsDocument = gql`
    mutation ShipStationUpdateOrderItems($orderId: Float!, $items: [OrderItemInput!]!) {
  updateOrderItems(orderId: $orderId, items: $items) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationUpdateOrderItemsMutationFn = Apollo.MutationFunction<ShipStationUpdateOrderItemsMutation, ShipStationUpdateOrderItemsMutationVariables>;

/**
 * __useShipStationUpdateOrderItemsMutation__
 *
 * To run a mutation, you first call `useShipStationUpdateOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationUpdateOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationUpdateOrderItemsMutation, { data, loading, error }] = useShipStationUpdateOrderItemsMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useShipStationUpdateOrderItemsMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationUpdateOrderItemsMutation, ShipStationUpdateOrderItemsMutationVariables>) {
        return Apollo.useMutation<ShipStationUpdateOrderItemsMutation, ShipStationUpdateOrderItemsMutationVariables>(ShipStationUpdateOrderItemsDocument, baseOptions);
      }
export type ShipStationUpdateOrderItemsMutationHookResult = ReturnType<typeof useShipStationUpdateOrderItemsMutation>;
export type ShipStationUpdateOrderItemsMutationResult = Apollo.MutationResult<ShipStationUpdateOrderItemsMutation>;
export type ShipStationUpdateOrderItemsMutationOptions = Apollo.BaseMutationOptions<ShipStationUpdateOrderItemsMutation, ShipStationUpdateOrderItemsMutationVariables>;
export const ShipStationUpdateOrderNotesDocument = gql`
    mutation ShipStationUpdateOrderNotes($orderId: Float!, $input: OrderNotesInput!) {
  updateOrderNotes(orderId: $orderId, input: $input) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;
export type ShipStationUpdateOrderNotesMutationFn = Apollo.MutationFunction<ShipStationUpdateOrderNotesMutation, ShipStationUpdateOrderNotesMutationVariables>;

/**
 * __useShipStationUpdateOrderNotesMutation__
 *
 * To run a mutation, you first call `useShipStationUpdateOrderNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipStationUpdateOrderNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipStationUpdateOrderNotesMutation, { data, loading, error }] = useShipStationUpdateOrderNotesMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipStationUpdateOrderNotesMutation(baseOptions?: Apollo.MutationHookOptions<ShipStationUpdateOrderNotesMutation, ShipStationUpdateOrderNotesMutationVariables>) {
        return Apollo.useMutation<ShipStationUpdateOrderNotesMutation, ShipStationUpdateOrderNotesMutationVariables>(ShipStationUpdateOrderNotesDocument, baseOptions);
      }
export type ShipStationUpdateOrderNotesMutationHookResult = ReturnType<typeof useShipStationUpdateOrderNotesMutation>;
export type ShipStationUpdateOrderNotesMutationResult = Apollo.MutationResult<ShipStationUpdateOrderNotesMutation>;
export type ShipStationUpdateOrderNotesMutationOptions = Apollo.BaseMutationOptions<ShipStationUpdateOrderNotesMutation, ShipStationUpdateOrderNotesMutationVariables>;
export const CreateSendCloudLabelsForOrderDocument = gql`
    mutation createSendCloudLabelsForOrder($orderNumbers: [String!]!) {
  createSendCloudLabelsForOrder(orderNumbers: $orderNumbers) {
    error
    parcelId
    parcel {
      name
      order_number
      country {
        name
      }
      tracking_number
    }
    normal_printer
    label_printer
  }
}
    `;
export type CreateSendCloudLabelsForOrderMutationFn = Apollo.MutationFunction<CreateSendCloudLabelsForOrderMutation, CreateSendCloudLabelsForOrderMutationVariables>;

/**
 * __useCreateSendCloudLabelsForOrderMutation__
 *
 * To run a mutation, you first call `useCreateSendCloudLabelsForOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSendCloudLabelsForOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSendCloudLabelsForOrderMutation, { data, loading, error }] = useCreateSendCloudLabelsForOrderMutation({
 *   variables: {
 *      orderNumbers: // value for 'orderNumbers'
 *   },
 * });
 */
export function useCreateSendCloudLabelsForOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateSendCloudLabelsForOrderMutation, CreateSendCloudLabelsForOrderMutationVariables>) {
        return Apollo.useMutation<CreateSendCloudLabelsForOrderMutation, CreateSendCloudLabelsForOrderMutationVariables>(CreateSendCloudLabelsForOrderDocument, baseOptions);
      }
export type CreateSendCloudLabelsForOrderMutationHookResult = ReturnType<typeof useCreateSendCloudLabelsForOrderMutation>;
export type CreateSendCloudLabelsForOrderMutationResult = Apollo.MutationResult<CreateSendCloudLabelsForOrderMutation>;
export type CreateSendCloudLabelsForOrderMutationOptions = Apollo.BaseMutationOptions<CreateSendCloudLabelsForOrderMutation, CreateSendCloudLabelsForOrderMutationVariables>;
export const BatchCreateSendCloudLabelsForOrderDocument = gql`
    mutation batchCreateSendCloudLabelsForOrder($orderNumbers: [String!]!) {
  batchCreateSendCloudLabelsForOrder(orderNumbers: $orderNumbers) {
    batch
    labels {
      error
      parcelId
      parcel {
        name
        order_number
        country {
          name
        }
        tracking_number
      }
      normal_printer
      label_printer
    }
  }
}
    `;
export type BatchCreateSendCloudLabelsForOrderMutationFn = Apollo.MutationFunction<BatchCreateSendCloudLabelsForOrderMutation, BatchCreateSendCloudLabelsForOrderMutationVariables>;

/**
 * __useBatchCreateSendCloudLabelsForOrderMutation__
 *
 * To run a mutation, you first call `useBatchCreateSendCloudLabelsForOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchCreateSendCloudLabelsForOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchCreateSendCloudLabelsForOrderMutation, { data, loading, error }] = useBatchCreateSendCloudLabelsForOrderMutation({
 *   variables: {
 *      orderNumbers: // value for 'orderNumbers'
 *   },
 * });
 */
export function useBatchCreateSendCloudLabelsForOrderMutation(baseOptions?: Apollo.MutationHookOptions<BatchCreateSendCloudLabelsForOrderMutation, BatchCreateSendCloudLabelsForOrderMutationVariables>) {
        return Apollo.useMutation<BatchCreateSendCloudLabelsForOrderMutation, BatchCreateSendCloudLabelsForOrderMutationVariables>(BatchCreateSendCloudLabelsForOrderDocument, baseOptions);
      }
export type BatchCreateSendCloudLabelsForOrderMutationHookResult = ReturnType<typeof useBatchCreateSendCloudLabelsForOrderMutation>;
export type BatchCreateSendCloudLabelsForOrderMutationResult = Apollo.MutationResult<BatchCreateSendCloudLabelsForOrderMutation>;
export type BatchCreateSendCloudLabelsForOrderMutationOptions = Apollo.BaseMutationOptions<BatchCreateSendCloudLabelsForOrderMutation, BatchCreateSendCloudLabelsForOrderMutationVariables>;
export const SendToSendCloudDocument = gql`
    mutation SendToSendCloud($orderId: Float!) {
  sendToSendCloud(orderId: $orderId) {
    orderId
    order {
      ...ShipStationOrder
    }
    parcel {
      ...SendCloudParcel
    }
  }
}
    ${ShipStationOrderFragmentDoc}
${SendCloudParcelFragmentDoc}`;
export type SendToSendCloudMutationFn = Apollo.MutationFunction<SendToSendCloudMutation, SendToSendCloudMutationVariables>;

/**
 * __useSendToSendCloudMutation__
 *
 * To run a mutation, you first call `useSendToSendCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToSendCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToSendCloudMutation, { data, loading, error }] = useSendToSendCloudMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSendToSendCloudMutation(baseOptions?: Apollo.MutationHookOptions<SendToSendCloudMutation, SendToSendCloudMutationVariables>) {
        return Apollo.useMutation<SendToSendCloudMutation, SendToSendCloudMutationVariables>(SendToSendCloudDocument, baseOptions);
      }
export type SendToSendCloudMutationHookResult = ReturnType<typeof useSendToSendCloudMutation>;
export type SendToSendCloudMutationResult = Apollo.MutationResult<SendToSendCloudMutation>;
export type SendToSendCloudMutationOptions = Apollo.BaseMutationOptions<SendToSendCloudMutation, SendToSendCloudMutationVariables>;
export const ShipStationGetOrderDocument = gql`
    query ShipStationGetOrder($orderId: Float!) {
  getOrder(orderId: $orderId) {
    ...ShipStationOrder
  }
}
    ${ShipStationOrderFragmentDoc}`;

/**
 * __useShipStationGetOrderQuery__
 *
 * To run a query within a React component, call `useShipStationGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationGetOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useShipStationGetOrderQuery(baseOptions: Apollo.QueryHookOptions<ShipStationGetOrderQuery, ShipStationGetOrderQueryVariables>) {
        return Apollo.useQuery<ShipStationGetOrderQuery, ShipStationGetOrderQueryVariables>(ShipStationGetOrderDocument, baseOptions);
      }
export function useShipStationGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationGetOrderQuery, ShipStationGetOrderQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationGetOrderQuery, ShipStationGetOrderQueryVariables>(ShipStationGetOrderDocument, baseOptions);
        }
export type ShipStationGetOrderQueryHookResult = ReturnType<typeof useShipStationGetOrderQuery>;
export type ShipStationGetOrderLazyQueryHookResult = ReturnType<typeof useShipStationGetOrderLazyQuery>;
export type ShipStationGetOrderQueryResult = Apollo.QueryResult<ShipStationGetOrderQuery, ShipStationGetOrderQueryVariables>;
export const SendCloudGetParcelDocument = gql`
    query SendCloudGetParcel($parcelId: Float, $trackingNumber: String) {
  getParcel(parcelId: $parcelId, trackingNumber: $trackingNumber) {
    ...SendCloudParcel
  }
}
    ${SendCloudParcelFragmentDoc}`;

/**
 * __useSendCloudGetParcelQuery__
 *
 * To run a query within a React component, call `useSendCloudGetParcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendCloudGetParcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendCloudGetParcelQuery({
 *   variables: {
 *      parcelId: // value for 'parcelId'
 *      trackingNumber: // value for 'trackingNumber'
 *   },
 * });
 */
export function useSendCloudGetParcelQuery(baseOptions?: Apollo.QueryHookOptions<SendCloudGetParcelQuery, SendCloudGetParcelQueryVariables>) {
        return Apollo.useQuery<SendCloudGetParcelQuery, SendCloudGetParcelQueryVariables>(SendCloudGetParcelDocument, baseOptions);
      }
export function useSendCloudGetParcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendCloudGetParcelQuery, SendCloudGetParcelQueryVariables>) {
          return Apollo.useLazyQuery<SendCloudGetParcelQuery, SendCloudGetParcelQueryVariables>(SendCloudGetParcelDocument, baseOptions);
        }
export type SendCloudGetParcelQueryHookResult = ReturnType<typeof useSendCloudGetParcelQuery>;
export type SendCloudGetParcelLazyQueryHookResult = ReturnType<typeof useSendCloudGetParcelLazyQuery>;
export type SendCloudGetParcelQueryResult = Apollo.QueryResult<SendCloudGetParcelQuery, SendCloudGetParcelQueryVariables>;
export const ShipStationListOrdersDocument = gql`
    query ShipStationListOrders($filter: ShipStationOrderFilter, $pagination: Pagination, $sort: Sort) {
  listOrders(filter: $filter, pagination: $pagination, sort: $sort) {
    total
    data {
      ...ShipStationOrder
    }
  }
}
    ${ShipStationOrderFragmentDoc}`;

/**
 * __useShipStationListOrdersQuery__
 *
 * To run a query within a React component, call `useShipStationListOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useShipStationListOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListOrdersQuery, ShipStationListOrdersQueryVariables>) {
        return Apollo.useQuery<ShipStationListOrdersQuery, ShipStationListOrdersQueryVariables>(ShipStationListOrdersDocument, baseOptions);
      }
export function useShipStationListOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListOrdersQuery, ShipStationListOrdersQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListOrdersQuery, ShipStationListOrdersQueryVariables>(ShipStationListOrdersDocument, baseOptions);
        }
export type ShipStationListOrdersQueryHookResult = ReturnType<typeof useShipStationListOrdersQuery>;
export type ShipStationListOrdersLazyQueryHookResult = ReturnType<typeof useShipStationListOrdersLazyQuery>;
export type ShipStationListOrdersQueryResult = Apollo.QueryResult<ShipStationListOrdersQuery, ShipStationListOrdersQueryVariables>;
export const ShipStationListOrdersByTagDocument = gql`
    query ShipStationListOrdersByTag($filter: ShipStationOrderByTagFilter!, $pagination: Pagination) {
  listOrdersByTag(filter: $filter, pagination: $pagination) {
    total
    data {
      ...ShipStationOrder
    }
  }
}
    ${ShipStationOrderFragmentDoc}`;

/**
 * __useShipStationListOrdersByTagQuery__
 *
 * To run a query within a React component, call `useShipStationListOrdersByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListOrdersByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListOrdersByTagQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useShipStationListOrdersByTagQuery(baseOptions: Apollo.QueryHookOptions<ShipStationListOrdersByTagQuery, ShipStationListOrdersByTagQueryVariables>) {
        return Apollo.useQuery<ShipStationListOrdersByTagQuery, ShipStationListOrdersByTagQueryVariables>(ShipStationListOrdersByTagDocument, baseOptions);
      }
export function useShipStationListOrdersByTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListOrdersByTagQuery, ShipStationListOrdersByTagQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListOrdersByTagQuery, ShipStationListOrdersByTagQueryVariables>(ShipStationListOrdersByTagDocument, baseOptions);
        }
export type ShipStationListOrdersByTagQueryHookResult = ReturnType<typeof useShipStationListOrdersByTagQuery>;
export type ShipStationListOrdersByTagLazyQueryHookResult = ReturnType<typeof useShipStationListOrdersByTagLazyQuery>;
export type ShipStationListOrdersByTagQueryResult = Apollo.QueryResult<ShipStationListOrdersByTagQuery, ShipStationListOrdersByTagQueryVariables>;
export const ShipStationListShipmentsDocument = gql`
    query ShipStationListShipments($filter: ShipStationShipmentsFilter, $pagination: Pagination, $sort: Sort) {
  listShipments(filter: $filter, pagination: $pagination, sort: $sort) {
    total
    data {
      ...ShipStationShipment
    }
  }
}
    ${ShipStationShipmentFragmentDoc}`;

/**
 * __useShipStationListShipmentsQuery__
 *
 * To run a query within a React component, call `useShipStationListShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListShipmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useShipStationListShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListShipmentsQuery, ShipStationListShipmentsQueryVariables>) {
        return Apollo.useQuery<ShipStationListShipmentsQuery, ShipStationListShipmentsQueryVariables>(ShipStationListShipmentsDocument, baseOptions);
      }
export function useShipStationListShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListShipmentsQuery, ShipStationListShipmentsQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListShipmentsQuery, ShipStationListShipmentsQueryVariables>(ShipStationListShipmentsDocument, baseOptions);
        }
export type ShipStationListShipmentsQueryHookResult = ReturnType<typeof useShipStationListShipmentsQuery>;
export type ShipStationListShipmentsLazyQueryHookResult = ReturnType<typeof useShipStationListShipmentsLazyQuery>;
export type ShipStationListShipmentsQueryResult = Apollo.QueryResult<ShipStationListShipmentsQuery, ShipStationListShipmentsQueryVariables>;
export const ShipStationListFulfillmentsDocument = gql`
    query ShipStationListFulfillments($filter: ShipStationFulfillmentsFilter, $pagination: Pagination, $sort: Sort) {
  listFulfillments(filter: $filter, pagination: $pagination, sort: $sort) {
    total
    data {
      ...ShipStationFulfillment
    }
  }
}
    ${ShipStationFulfillmentFragmentDoc}`;

/**
 * __useShipStationListFulfillmentsQuery__
 *
 * To run a query within a React component, call `useShipStationListFulfillmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListFulfillmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListFulfillmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useShipStationListFulfillmentsQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListFulfillmentsQuery, ShipStationListFulfillmentsQueryVariables>) {
        return Apollo.useQuery<ShipStationListFulfillmentsQuery, ShipStationListFulfillmentsQueryVariables>(ShipStationListFulfillmentsDocument, baseOptions);
      }
export function useShipStationListFulfillmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListFulfillmentsQuery, ShipStationListFulfillmentsQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListFulfillmentsQuery, ShipStationListFulfillmentsQueryVariables>(ShipStationListFulfillmentsDocument, baseOptions);
        }
export type ShipStationListFulfillmentsQueryHookResult = ReturnType<typeof useShipStationListFulfillmentsQuery>;
export type ShipStationListFulfillmentsLazyQueryHookResult = ReturnType<typeof useShipStationListFulfillmentsLazyQuery>;
export type ShipStationListFulfillmentsQueryResult = Apollo.QueryResult<ShipStationListFulfillmentsQuery, ShipStationListFulfillmentsQueryVariables>;
export const ShipStationListProductsDocument = gql`
    query ShipStationListProducts($filter: ShipStationProductFilter, $pagination: Pagination, $sort: Sort) {
  listProducts(filter: $filter, pagination: $pagination, sort: $sort) {
    total
    data {
      ...ShipStationProduct
    }
  }
}
    ${ShipStationProductFragmentDoc}`;

/**
 * __useShipStationListProductsQuery__
 *
 * To run a query within a React component, call `useShipStationListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useShipStationListProductsQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListProductsQuery, ShipStationListProductsQueryVariables>) {
        return Apollo.useQuery<ShipStationListProductsQuery, ShipStationListProductsQueryVariables>(ShipStationListProductsDocument, baseOptions);
      }
export function useShipStationListProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListProductsQuery, ShipStationListProductsQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListProductsQuery, ShipStationListProductsQueryVariables>(ShipStationListProductsDocument, baseOptions);
        }
export type ShipStationListProductsQueryHookResult = ReturnType<typeof useShipStationListProductsQuery>;
export type ShipStationListProductsLazyQueryHookResult = ReturnType<typeof useShipStationListProductsLazyQuery>;
export type ShipStationListProductsQueryResult = Apollo.QueryResult<ShipStationListProductsQuery, ShipStationListProductsQueryVariables>;
export const ShipStationListTagsDocument = gql`
    query ShipStationListTags {
  listTags {
    total
    data {
      id
      tagId
      name
      color
    }
  }
}
    `;

/**
 * __useShipStationListTagsQuery__
 *
 * To run a query within a React component, call `useShipStationListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipStationListTagsQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListTagsQuery, ShipStationListTagsQueryVariables>) {
        return Apollo.useQuery<ShipStationListTagsQuery, ShipStationListTagsQueryVariables>(ShipStationListTagsDocument, baseOptions);
      }
export function useShipStationListTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListTagsQuery, ShipStationListTagsQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListTagsQuery, ShipStationListTagsQueryVariables>(ShipStationListTagsDocument, baseOptions);
        }
export type ShipStationListTagsQueryHookResult = ReturnType<typeof useShipStationListTagsQuery>;
export type ShipStationListTagsLazyQueryHookResult = ReturnType<typeof useShipStationListTagsLazyQuery>;
export type ShipStationListTagsQueryResult = Apollo.QueryResult<ShipStationListTagsQuery, ShipStationListTagsQueryVariables>;
export const ShipStationListStoresDocument = gql`
    query ShipStationListStores {
  listStores {
    total
    data {
      ...ShipStationStore
    }
  }
}
    ${ShipStationStoreFragmentDoc}`;

/**
 * __useShipStationListStoresQuery__
 *
 * To run a query within a React component, call `useShipStationListStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipStationListStoresQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListStoresQuery, ShipStationListStoresQueryVariables>) {
        return Apollo.useQuery<ShipStationListStoresQuery, ShipStationListStoresQueryVariables>(ShipStationListStoresDocument, baseOptions);
      }
export function useShipStationListStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListStoresQuery, ShipStationListStoresQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListStoresQuery, ShipStationListStoresQueryVariables>(ShipStationListStoresDocument, baseOptions);
        }
export type ShipStationListStoresQueryHookResult = ReturnType<typeof useShipStationListStoresQuery>;
export type ShipStationListStoresLazyQueryHookResult = ReturnType<typeof useShipStationListStoresLazyQuery>;
export type ShipStationListStoresQueryResult = Apollo.QueryResult<ShipStationListStoresQuery, ShipStationListStoresQueryVariables>;
export const ShipStationListCarriersDocument = gql`
    query ShipStationListCarriers {
  listCarriers {
    total
    data {
      ...ShipStationCarrier
    }
  }
}
    ${ShipStationCarrierFragmentDoc}`;

/**
 * __useShipStationListCarriersQuery__
 *
 * To run a query within a React component, call `useShipStationListCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListCarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipStationListCarriersQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListCarriersQuery, ShipStationListCarriersQueryVariables>) {
        return Apollo.useQuery<ShipStationListCarriersQuery, ShipStationListCarriersQueryVariables>(ShipStationListCarriersDocument, baseOptions);
      }
export function useShipStationListCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListCarriersQuery, ShipStationListCarriersQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListCarriersQuery, ShipStationListCarriersQueryVariables>(ShipStationListCarriersDocument, baseOptions);
        }
export type ShipStationListCarriersQueryHookResult = ReturnType<typeof useShipStationListCarriersQuery>;
export type ShipStationListCarriersLazyQueryHookResult = ReturnType<typeof useShipStationListCarriersLazyQuery>;
export type ShipStationListCarriersQueryResult = Apollo.QueryResult<ShipStationListCarriersQuery, ShipStationListCarriersQueryVariables>;
export const ShipStationGetCarrierDocument = gql`
    query ShipStationGetCarrier($carrierCode: String!) {
  getCarrier(carrierCode: $carrierCode) {
    ...ShipStationCarrier
    packages {
      ...ShipStationCarrierPackage
    }
    services {
      ...ShipStationCarrierService
    }
  }
}
    ${ShipStationCarrierFragmentDoc}
${ShipStationCarrierPackageFragmentDoc}
${ShipStationCarrierServiceFragmentDoc}`;

/**
 * __useShipStationGetCarrierQuery__
 *
 * To run a query within a React component, call `useShipStationGetCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationGetCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationGetCarrierQuery({
 *   variables: {
 *      carrierCode: // value for 'carrierCode'
 *   },
 * });
 */
export function useShipStationGetCarrierQuery(baseOptions: Apollo.QueryHookOptions<ShipStationGetCarrierQuery, ShipStationGetCarrierQueryVariables>) {
        return Apollo.useQuery<ShipStationGetCarrierQuery, ShipStationGetCarrierQueryVariables>(ShipStationGetCarrierDocument, baseOptions);
      }
export function useShipStationGetCarrierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationGetCarrierQuery, ShipStationGetCarrierQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationGetCarrierQuery, ShipStationGetCarrierQueryVariables>(ShipStationGetCarrierDocument, baseOptions);
        }
export type ShipStationGetCarrierQueryHookResult = ReturnType<typeof useShipStationGetCarrierQuery>;
export type ShipStationGetCarrierLazyQueryHookResult = ReturnType<typeof useShipStationGetCarrierLazyQuery>;
export type ShipStationGetCarrierQueryResult = Apollo.QueryResult<ShipStationGetCarrierQuery, ShipStationGetCarrierQueryVariables>;
export const ShipStationListWarehousesDocument = gql`
    query ShipStationListWarehouses {
  listWarehouses {
    total
    data {
      ...ShipStationWarehouse
    }
  }
}
    ${ShipStationWarehouseFragmentDoc}`;

/**
 * __useShipStationListWarehousesQuery__
 *
 * To run a query within a React component, call `useShipStationListWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationListWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationListWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipStationListWarehousesQuery(baseOptions?: Apollo.QueryHookOptions<ShipStationListWarehousesQuery, ShipStationListWarehousesQueryVariables>) {
        return Apollo.useQuery<ShipStationListWarehousesQuery, ShipStationListWarehousesQueryVariables>(ShipStationListWarehousesDocument, baseOptions);
      }
export function useShipStationListWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipStationListWarehousesQuery, ShipStationListWarehousesQueryVariables>) {
          return Apollo.useLazyQuery<ShipStationListWarehousesQuery, ShipStationListWarehousesQueryVariables>(ShipStationListWarehousesDocument, baseOptions);
        }
export type ShipStationListWarehousesQueryHookResult = ReturnType<typeof useShipStationListWarehousesQuery>;
export type ShipStationListWarehousesLazyQueryHookResult = ReturnType<typeof useShipStationListWarehousesLazyQuery>;
export type ShipStationListWarehousesQueryResult = Apollo.QueryResult<ShipStationListWarehousesQuery, ShipStationListWarehousesQueryVariables>;
export const UserInfoPageDocument = gql`
    query UserInfoPage($filter: UserInfoFilter, $pagination: Pagination, $sort: Sort) {
  userInfoPage(filter: $filter, pagination: $pagination, sort: $sort) {
    total
    data {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useUserInfoPageQuery__
 *
 * To run a query within a React component, call `useUserInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserInfoPageQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoPageQuery, UserInfoPageQueryVariables>) {
        return Apollo.useQuery<UserInfoPageQuery, UserInfoPageQueryVariables>(UserInfoPageDocument, baseOptions);
      }
export function useUserInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoPageQuery, UserInfoPageQueryVariables>) {
          return Apollo.useLazyQuery<UserInfoPageQuery, UserInfoPageQueryVariables>(UserInfoPageDocument, baseOptions);
        }
export type UserInfoPageQueryHookResult = ReturnType<typeof useUserInfoPageQuery>;
export type UserInfoPageLazyQueryHookResult = ReturnType<typeof useUserInfoPageLazyQuery>;
export type UserInfoPageQueryResult = Apollo.QueryResult<UserInfoPageQuery, UserInfoPageQueryVariables>;
export const AccountDocument = gql`
    query Account {
  account {
    customer {
      ...Customer
      mainSubscription {
        ...RebillySubscription
      }
      subscriptions {
        ...RebillySubscription
      }
      paymentCards {
        ...Card
      }
    }
    userInfo {
      ...UserInfo
    }
    uid
    email
    customerId
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}
${UserInfoFragmentDoc}`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const AccountsDocument = gql`
    query Accounts($uids: [String!], $emails: [String!]) {
  accounts(uids: $uids, emails: $emails) {
    customer {
      ...Customer
      mainSubscription {
        ...RebillySubscription
      }
      subscriptions {
        ...RebillySubscription
      }
      paymentCards {
        ...Card
      }
    }
    userInfo {
      ...UserInfo
    }
    uid
    email
    customerId
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}
${UserInfoFragmentDoc}`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      uids: // value for 'uids'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, baseOptions);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, baseOptions);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Customer
    mainSubscription {
      ...RebillySubscription
    }
    subscriptions {
      ...RebillySubscription
    }
    paymentCards {
      ...Card
    }
    userInfo {
      ...UserInfo
    }
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}
${UserInfoFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const KycDocument = gql`
    query Kyc {
  me {
    id
    kycDocuments {
      ...Kyc
    }
    kycMaxAttempts {
      ...KycMaxAttempts
    }
  }
}
    ${KycFragmentDoc}
${KycMaxAttemptsFragmentDoc}`;

/**
 * __useKycQuery__
 *
 * To run a query within a React component, call `useKycQuery` and pass it any options that fit your needs.
 * When your component renders, `useKycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKycQuery({
 *   variables: {
 *   },
 * });
 */
export function useKycQuery(baseOptions?: Apollo.QueryHookOptions<KycQuery, KycQueryVariables>) {
        return Apollo.useQuery<KycQuery, KycQueryVariables>(KycDocument, baseOptions);
      }
export function useKycLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KycQuery, KycQueryVariables>) {
          return Apollo.useLazyQuery<KycQuery, KycQueryVariables>(KycDocument, baseOptions);
        }
export type KycQueryHookResult = ReturnType<typeof useKycQuery>;
export type KycLazyQueryHookResult = ReturnType<typeof useKycLazyQuery>;
export type KycQueryResult = Apollo.QueryResult<KycQuery, KycQueryVariables>;
export const KycByIdDocument = gql`
    query KycById($id: String!) {
  kycDocumentById(id: $id) {
    ...Kyc
    download
  }
}
    ${KycFragmentDoc}`;

/**
 * __useKycByIdQuery__
 *
 * To run a query within a React component, call `useKycByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useKycByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKycByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKycByIdQuery(baseOptions: Apollo.QueryHookOptions<KycByIdQuery, KycByIdQueryVariables>) {
        return Apollo.useQuery<KycByIdQuery, KycByIdQueryVariables>(KycByIdDocument, baseOptions);
      }
export function useKycByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KycByIdQuery, KycByIdQueryVariables>) {
          return Apollo.useLazyQuery<KycByIdQuery, KycByIdQueryVariables>(KycByIdDocument, baseOptions);
        }
export type KycByIdQueryHookResult = ReturnType<typeof useKycByIdQuery>;
export type KycByIdLazyQueryHookResult = ReturnType<typeof useKycByIdLazyQuery>;
export type KycByIdQueryResult = Apollo.QueryResult<KycByIdQuery, KycByIdQueryVariables>;
export const CreateKycDocument = gql`
    mutation CreateKyc($input: KycDocumentInput!) {
  createKycDocument(input: $input) {
    customerId
    kycDocument {
      ...Kyc
    }
    kycMaxAttempts {
      ...KycMaxAttempts
    }
  }
}
    ${KycFragmentDoc}
${KycMaxAttemptsFragmentDoc}`;
export type CreateKycMutationFn = Apollo.MutationFunction<CreateKycMutation, CreateKycMutationVariables>;

/**
 * __useCreateKycMutation__
 *
 * To run a mutation, you first call `useCreateKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKycMutation, { data, loading, error }] = useCreateKycMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKycMutation(baseOptions?: Apollo.MutationHookOptions<CreateKycMutation, CreateKycMutationVariables>) {
        return Apollo.useMutation<CreateKycMutation, CreateKycMutationVariables>(CreateKycDocument, baseOptions);
      }
export type CreateKycMutationHookResult = ReturnType<typeof useCreateKycMutation>;
export type CreateKycMutationResult = Apollo.MutationResult<CreateKycMutation>;
export type CreateKycMutationOptions = Apollo.BaseMutationOptions<CreateKycMutation, CreateKycMutationVariables>;
export const UpdateKycDocument = gql`
    mutation UpdateKyc($id: String!, $input: KycDocumentInput!) {
  updateKycDocument(id: $id, input: $input) {
    customerId
    kycDocument {
      ...Kyc
    }
    kycMaxAttempts {
      ...KycMaxAttempts
    }
  }
}
    ${KycFragmentDoc}
${KycMaxAttemptsFragmentDoc}`;
export type UpdateKycMutationFn = Apollo.MutationFunction<UpdateKycMutation, UpdateKycMutationVariables>;

/**
 * __useUpdateKycMutation__
 *
 * To run a mutation, you first call `useUpdateKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKycMutation, { data, loading, error }] = useUpdateKycMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKycMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKycMutation, UpdateKycMutationVariables>) {
        return Apollo.useMutation<UpdateKycMutation, UpdateKycMutationVariables>(UpdateKycDocument, baseOptions);
      }
export type UpdateKycMutationHookResult = ReturnType<typeof useUpdateKycMutation>;
export type UpdateKycMutationResult = Apollo.MutationResult<UpdateKycMutation>;
export type UpdateKycMutationOptions = Apollo.BaseMutationOptions<UpdateKycMutation, UpdateKycMutationVariables>;
export const AcceptKycDocument = gql`
    mutation AcceptKyc($id: String!) {
  acceptKycDocument(id: $id) {
    ...Kyc
  }
}
    ${KycFragmentDoc}`;
export type AcceptKycMutationFn = Apollo.MutationFunction<AcceptKycMutation, AcceptKycMutationVariables>;

/**
 * __useAcceptKycMutation__
 *
 * To run a mutation, you first call `useAcceptKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptKycMutation, { data, loading, error }] = useAcceptKycMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptKycMutation(baseOptions?: Apollo.MutationHookOptions<AcceptKycMutation, AcceptKycMutationVariables>) {
        return Apollo.useMutation<AcceptKycMutation, AcceptKycMutationVariables>(AcceptKycDocument, baseOptions);
      }
export type AcceptKycMutationHookResult = ReturnType<typeof useAcceptKycMutation>;
export type AcceptKycMutationResult = Apollo.MutationResult<AcceptKycMutation>;
export type AcceptKycMutationOptions = Apollo.BaseMutationOptions<AcceptKycMutation, AcceptKycMutationVariables>;
export const RejectKycDocument = gql`
    mutation RejectKyc($id: String!, $type: String, $message: String) {
  rejectKycDocument(id: $id, type: $type, message: $message) {
    ...Kyc
  }
}
    ${KycFragmentDoc}`;
export type RejectKycMutationFn = Apollo.MutationFunction<RejectKycMutation, RejectKycMutationVariables>;

/**
 * __useRejectKycMutation__
 *
 * To run a mutation, you first call `useRejectKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectKycMutation, { data, loading, error }] = useRejectKycMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useRejectKycMutation(baseOptions?: Apollo.MutationHookOptions<RejectKycMutation, RejectKycMutationVariables>) {
        return Apollo.useMutation<RejectKycMutation, RejectKycMutationVariables>(RejectKycDocument, baseOptions);
      }
export type RejectKycMutationHookResult = ReturnType<typeof useRejectKycMutation>;
export type RejectKycMutationResult = Apollo.MutationResult<RejectKycMutation>;
export type RejectKycMutationOptions = Apollo.BaseMutationOptions<RejectKycMutation, RejectKycMutationVariables>;
export const CustomerProfileDocument = gql`
    query CustomerProfile($uid: String!) {
  customerProfileByUid(uid: $uid) {
    uid
    email
    photoURL
  }
}
    `;

/**
 * __useCustomerProfileQuery__
 *
 * To run a query within a React component, call `useCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerProfileQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCustomerProfileQuery(baseOptions: Apollo.QueryHookOptions<CustomerProfileQuery, CustomerProfileQueryVariables>) {
        return Apollo.useQuery<CustomerProfileQuery, CustomerProfileQueryVariables>(CustomerProfileDocument, baseOptions);
      }
export function useCustomerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerProfileQuery, CustomerProfileQueryVariables>) {
          return Apollo.useLazyQuery<CustomerProfileQuery, CustomerProfileQueryVariables>(CustomerProfileDocument, baseOptions);
        }
export type CustomerProfileQueryHookResult = ReturnType<typeof useCustomerProfileQuery>;
export type CustomerProfileLazyQueryHookResult = ReturnType<typeof useCustomerProfileLazyQuery>;
export type CustomerProfileQueryResult = Apollo.QueryResult<CustomerProfileQuery, CustomerProfileQueryVariables>;
export const CustomerDocument = gql`
    query Customer($id: String, $uid: String, $email: String) {
  customer(id: $id, uid: $uid, email: $email) {
    ...Customer
    mainSubscription {
      ...RebillySubscription
    }
    subscriptions {
      ...RebillySubscription
    }
    pastSubscriptions {
      ...RebillySubscription
    }
    paymentCards {
      ...Card
    }
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const PlansDocument = gql`
    query Plans {
  plans {
    id
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const InvoiceDocument = gql`
    query Invoice($id: String!) {
  invoice(id: $id) {
    ...Invoice
  }
}
    ${InvoiceFragmentDoc}`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, baseOptions);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, baseOptions);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const CheckoutDocument = gql`
    mutation Checkout($planId: String!, $paymentCardId: String!, $customer: CustomerInput, $shipping: AddressInput, $billing: AddressInput) {
  checkout(
    planId: $planId
    customer: $customer
    shipping: $shipping
    billing: $billing
    paymentInstrument: {method: "payment-card", paymentCardId: $paymentCardId}
  ) {
    transaction {
      id
      amount
      status
      result
    }
    customer {
      ...Customer
      mainSubscription {
        ...RebillySubscription
      }
      subscriptions {
        ...RebillySubscription
      }
      paymentCards {
        ...Card
      }
    }
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}`;
export type CheckoutMutationFn = Apollo.MutationFunction<CheckoutMutation, CheckoutMutationVariables>;

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      paymentCardId: // value for 'paymentCardId'
 *      customer: // value for 'customer'
 *      shipping: // value for 'shipping'
 *      billing: // value for 'billing'
 *   },
 * });
 */
export function useCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>) {
        return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, baseOptions);
      }
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>;
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>;
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<CheckoutMutation, CheckoutMutationVariables>;
export const CheckoutV2Document = gql`
    mutation CheckoutV2($planId: String!, $paymentCardId: String!, $customer: CustomerInput, $shipping: AddressInput, $billing: AddressInput) {
  checkout(
    planId: $planId
    customer: $customer
    shipping: $shipping
    billing: $billing
    paymentInstruction: {paymentInstrumentId: $paymentCardId}
  ) {
    transaction {
      id
      amount
      status
      result
    }
    customer {
      ...Customer
      mainSubscription {
        ...RebillySubscription
      }
      subscriptions {
        ...RebillySubscription
      }
      paymentCards {
        ...Card
      }
    }
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}`;
export type CheckoutV2MutationFn = Apollo.MutationFunction<CheckoutV2Mutation, CheckoutV2MutationVariables>;

/**
 * __useCheckoutV2Mutation__
 *
 * To run a mutation, you first call `useCheckoutV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutV2Mutation, { data, loading, error }] = useCheckoutV2Mutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      paymentCardId: // value for 'paymentCardId'
 *      customer: // value for 'customer'
 *      shipping: // value for 'shipping'
 *      billing: // value for 'billing'
 *   },
 * });
 */
export function useCheckoutV2Mutation(baseOptions?: Apollo.MutationHookOptions<CheckoutV2Mutation, CheckoutV2MutationVariables>) {
        return Apollo.useMutation<CheckoutV2Mutation, CheckoutV2MutationVariables>(CheckoutV2Document, baseOptions);
      }
export type CheckoutV2MutationHookResult = ReturnType<typeof useCheckoutV2Mutation>;
export type CheckoutV2MutationResult = Apollo.MutationResult<CheckoutV2Mutation>;
export type CheckoutV2MutationOptions = Apollo.BaseMutationOptions<CheckoutV2Mutation, CheckoutV2MutationVariables>;
export const TransactionDocument = gql`
    mutation Transaction($invoiceId: String!, $paymentCardId: String!) {
  createTransaction(
    invoiceId: $invoiceId
    paymentInstrument: {method: "payment-card", paymentCardId: $paymentCardId}
  ) {
    id
    status
    result
  }
}
    `;
export type TransactionMutationFn = Apollo.MutationFunction<TransactionMutation, TransactionMutationVariables>;

/**
 * __useTransactionMutation__
 *
 * To run a mutation, you first call `useTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionMutation, { data, loading, error }] = useTransactionMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentCardId: // value for 'paymentCardId'
 *   },
 * });
 */
export function useTransactionMutation(baseOptions?: Apollo.MutationHookOptions<TransactionMutation, TransactionMutationVariables>) {
        return Apollo.useMutation<TransactionMutation, TransactionMutationVariables>(TransactionDocument, baseOptions);
      }
export type TransactionMutationHookResult = ReturnType<typeof useTransactionMutation>;
export type TransactionMutationResult = Apollo.MutationResult<TransactionMutation>;
export type TransactionMutationOptions = Apollo.BaseMutationOptions<TransactionMutation, TransactionMutationVariables>;
export const TransactionV2Document = gql`
    mutation TransactionV2($invoiceId: String!, $paymentCardId: String!) {
  createTransaction(
    invoiceId: $invoiceId
    paymentInstruction: {paymentInstrumentId: $paymentCardId}
  ) {
    id
    status
    result
  }
}
    `;
export type TransactionV2MutationFn = Apollo.MutationFunction<TransactionV2Mutation, TransactionV2MutationVariables>;

/**
 * __useTransactionV2Mutation__
 *
 * To run a mutation, you first call `useTransactionV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionV2Mutation, { data, loading, error }] = useTransactionV2Mutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentCardId: // value for 'paymentCardId'
 *   },
 * });
 */
export function useTransactionV2Mutation(baseOptions?: Apollo.MutationHookOptions<TransactionV2Mutation, TransactionV2MutationVariables>) {
        return Apollo.useMutation<TransactionV2Mutation, TransactionV2MutationVariables>(TransactionV2Document, baseOptions);
      }
export type TransactionV2MutationHookResult = ReturnType<typeof useTransactionV2Mutation>;
export type TransactionV2MutationResult = Apollo.MutationResult<TransactionV2Mutation>;
export type TransactionV2MutationOptions = Apollo.BaseMutationOptions<TransactionV2Mutation, TransactionV2MutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($subscriptionId: String!) {
  cancelSubscription(subscriptionId: $subscriptionId) {
    ...RebillySubscription
  }
}
    ${RebillySubscriptionFragmentDoc}`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, baseOptions);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ReactivateSubscriptionDocument = gql`
    mutation ReactivateSubscription($subscriptionId: String!) {
  reactivateSubscription(subscriptionId: $subscriptionId) {
    ...RebillySubscription
  }
}
    ${RebillySubscriptionFragmentDoc}`;
export type ReactivateSubscriptionMutationFn = Apollo.MutationFunction<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;

/**
 * __useReactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSubscriptionMutation, { data, loading, error }] = useReactivateSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useReactivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>) {
        return Apollo.useMutation<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>(ReactivateSubscriptionDocument, baseOptions);
      }
export type ReactivateSubscriptionMutationHookResult = ReturnType<typeof useReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationResult = Apollo.MutationResult<ReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;
export const ChangeSubscriptionPreviewDocument = gql`
    mutation ChangeSubscriptionPreview($subscriptionId: String!, $planId: String!) {
  changeSubscriptionPreview(subscriptionId: $subscriptionId, planId: $planId) {
    status
    billingStatus
    items {
      planId
    }
    lineItemSubtotal
    lineItems {
      quantity
      unitPriceAmount
      unitPriceCurrency
      description
      type
    }
  }
}
    `;
export type ChangeSubscriptionPreviewMutationFn = Apollo.MutationFunction<ChangeSubscriptionPreviewMutation, ChangeSubscriptionPreviewMutationVariables>;

/**
 * __useChangeSubscriptionPreviewMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionPreviewMutation, { data, loading, error }] = useChangeSubscriptionPreviewMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useChangeSubscriptionPreviewMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionPreviewMutation, ChangeSubscriptionPreviewMutationVariables>) {
        return Apollo.useMutation<ChangeSubscriptionPreviewMutation, ChangeSubscriptionPreviewMutationVariables>(ChangeSubscriptionPreviewDocument, baseOptions);
      }
export type ChangeSubscriptionPreviewMutationHookResult = ReturnType<typeof useChangeSubscriptionPreviewMutation>;
export type ChangeSubscriptionPreviewMutationResult = Apollo.MutationResult<ChangeSubscriptionPreviewMutation>;
export type ChangeSubscriptionPreviewMutationOptions = Apollo.BaseMutationOptions<ChangeSubscriptionPreviewMutation, ChangeSubscriptionPreviewMutationVariables>;
export const ChangeSubscriptionDocument = gql`
    mutation ChangeSubscription($subscriptionId: String!, $planId: String!, $paymentCardId: String!) {
  changeSubscription(
    subscriptionId: $subscriptionId
    planId: $planId
    paymentInstrument: {method: "payment-card", paymentCardId: $paymentCardId}
  ) {
    ...RebillySubscription
  }
}
    ${RebillySubscriptionFragmentDoc}`;
export type ChangeSubscriptionMutationFn = Apollo.MutationFunction<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>;

/**
 * __useChangeSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionMutation, { data, loading, error }] = useChangeSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      planId: // value for 'planId'
 *      paymentCardId: // value for 'paymentCardId'
 *   },
 * });
 */
export function useChangeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>) {
        return Apollo.useMutation<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>(ChangeSubscriptionDocument, baseOptions);
      }
export type ChangeSubscriptionMutationHookResult = ReturnType<typeof useChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationResult = Apollo.MutationResult<ChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($firstName: String, $lastName: String, $phone: String) {
  createCustomer(firstName: $firstName, lastName: $lastName, phone: $phone) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const AddPaymentDocument = gql`
    mutation AddPayment($paymentToken: String!, $customerId: String, $customer: CustomerInput) {
  addPayment(
    paymentToken: $paymentToken
    customerId: $customerId
    customer: $customer
  ) {
    ...Customer
    subscriptions {
      ...RebillySubscription
    }
    paymentCards {
      ...Card
    }
  }
}
    ${CustomerFragmentDoc}
${RebillySubscriptionFragmentDoc}
${CardFragmentDoc}`;
export type AddPaymentMutationFn = Apollo.MutationFunction<AddPaymentMutation, AddPaymentMutationVariables>;

/**
 * __useAddPaymentMutation__
 *
 * To run a mutation, you first call `useAddPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMutation, { data, loading, error }] = useAddPaymentMutation({
 *   variables: {
 *      paymentToken: // value for 'paymentToken'
 *      customerId: // value for 'customerId'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useAddPaymentMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentMutation, AddPaymentMutationVariables>) {
        return Apollo.useMutation<AddPaymentMutation, AddPaymentMutationVariables>(AddPaymentDocument, baseOptions);
      }
export type AddPaymentMutationHookResult = ReturnType<typeof useAddPaymentMutation>;
export type AddPaymentMutationResult = Apollo.MutationResult<AddPaymentMutation>;
export type AddPaymentMutationOptions = Apollo.BaseMutationOptions<AddPaymentMutation, AddPaymentMutationVariables>;
export const CumulativeSubscriptionCountDocument = gql`
    query CumulativeSubscriptionCount($start: String, $end: String) {
  cumulativeSubscriptionCount(start: $start, end: $end) {
    data {
      ...CumulativeSubscriptionCount
    }
  }
}
    ${CumulativeSubscriptionCountFragmentDoc}`;

/**
 * __useCumulativeSubscriptionCountQuery__
 *
 * To run a query within a React component, call `useCumulativeSubscriptionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeSubscriptionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeSubscriptionCountQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCumulativeSubscriptionCountQuery(baseOptions?: Apollo.QueryHookOptions<CumulativeSubscriptionCountQuery, CumulativeSubscriptionCountQueryVariables>) {
        return Apollo.useQuery<CumulativeSubscriptionCountQuery, CumulativeSubscriptionCountQueryVariables>(CumulativeSubscriptionCountDocument, baseOptions);
      }
export function useCumulativeSubscriptionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CumulativeSubscriptionCountQuery, CumulativeSubscriptionCountQueryVariables>) {
          return Apollo.useLazyQuery<CumulativeSubscriptionCountQuery, CumulativeSubscriptionCountQueryVariables>(CumulativeSubscriptionCountDocument, baseOptions);
        }
export type CumulativeSubscriptionCountQueryHookResult = ReturnType<typeof useCumulativeSubscriptionCountQuery>;
export type CumulativeSubscriptionCountLazyQueryHookResult = ReturnType<typeof useCumulativeSubscriptionCountLazyQuery>;
export type CumulativeSubscriptionCountQueryResult = Apollo.QueryResult<CumulativeSubscriptionCountQuery, CumulativeSubscriptionCountQueryVariables>;
export const CumulativeTransactionCountDocument = gql`
    query CumulativeTransactionCount($start: String, $end: String) {
  cumulativeTransactionCount(start: $start, end: $end) {
    data {
      ...CumulativeTransactionCount
    }
  }
}
    ${CumulativeTransactionCountFragmentDoc}`;

/**
 * __useCumulativeTransactionCountQuery__
 *
 * To run a query within a React component, call `useCumulativeTransactionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeTransactionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeTransactionCountQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCumulativeTransactionCountQuery(baseOptions?: Apollo.QueryHookOptions<CumulativeTransactionCountQuery, CumulativeTransactionCountQueryVariables>) {
        return Apollo.useQuery<CumulativeTransactionCountQuery, CumulativeTransactionCountQueryVariables>(CumulativeTransactionCountDocument, baseOptions);
      }
export function useCumulativeTransactionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CumulativeTransactionCountQuery, CumulativeTransactionCountQueryVariables>) {
          return Apollo.useLazyQuery<CumulativeTransactionCountQuery, CumulativeTransactionCountQueryVariables>(CumulativeTransactionCountDocument, baseOptions);
        }
export type CumulativeTransactionCountQueryHookResult = ReturnType<typeof useCumulativeTransactionCountQuery>;
export type CumulativeTransactionCountLazyQueryHookResult = ReturnType<typeof useCumulativeTransactionCountLazyQuery>;
export type CumulativeTransactionCountQueryResult = Apollo.QueryResult<CumulativeTransactionCountQuery, CumulativeTransactionCountQueryVariables>;
export const CumulativeStatsDocument = gql`
    query CumulativeStats($start: String, $end: String) {
  cumulativeSubscriptionCount(start: $start, end: $end) {
    data {
      ...CumulativeSubscriptionCount
    }
  }
  cumulativeTransactionCount(start: $start, end: $end) {
    data {
      ...CumulativeTransactionCount
    }
  }
}
    ${CumulativeSubscriptionCountFragmentDoc}
${CumulativeTransactionCountFragmentDoc}`;

/**
 * __useCumulativeStatsQuery__
 *
 * To run a query within a React component, call `useCumulativeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeStatsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCumulativeStatsQuery(baseOptions?: Apollo.QueryHookOptions<CumulativeStatsQuery, CumulativeStatsQueryVariables>) {
        return Apollo.useQuery<CumulativeStatsQuery, CumulativeStatsQueryVariables>(CumulativeStatsDocument, baseOptions);
      }
export function useCumulativeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CumulativeStatsQuery, CumulativeStatsQueryVariables>) {
          return Apollo.useLazyQuery<CumulativeStatsQuery, CumulativeStatsQueryVariables>(CumulativeStatsDocument, baseOptions);
        }
export type CumulativeStatsQueryHookResult = ReturnType<typeof useCumulativeStatsQuery>;
export type CumulativeStatsLazyQueryHookResult = ReturnType<typeof useCumulativeStatsLazyQuery>;
export type CumulativeStatsQueryResult = Apollo.QueryResult<CumulativeStatsQuery, CumulativeStatsQueryVariables>;
export const DeactivatePaymentCardDocument = gql`
    mutation DeactivatePaymentCard($paymentCardId: String!) {
  deactivatePaymentCard(paymentCardId: $paymentCardId) {
    ...Card
  }
}
    ${CardFragmentDoc}`;
export type DeactivatePaymentCardMutationFn = Apollo.MutationFunction<DeactivatePaymentCardMutation, DeactivatePaymentCardMutationVariables>;

/**
 * __useDeactivatePaymentCardMutation__
 *
 * To run a mutation, you first call `useDeactivatePaymentCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePaymentCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePaymentCardMutation, { data, loading, error }] = useDeactivatePaymentCardMutation({
 *   variables: {
 *      paymentCardId: // value for 'paymentCardId'
 *   },
 * });
 */
export function useDeactivatePaymentCardMutation(baseOptions?: Apollo.MutationHookOptions<DeactivatePaymentCardMutation, DeactivatePaymentCardMutationVariables>) {
        return Apollo.useMutation<DeactivatePaymentCardMutation, DeactivatePaymentCardMutationVariables>(DeactivatePaymentCardDocument, baseOptions);
      }
export type DeactivatePaymentCardMutationHookResult = ReturnType<typeof useDeactivatePaymentCardMutation>;
export type DeactivatePaymentCardMutationResult = Apollo.MutationResult<DeactivatePaymentCardMutation>;
export type DeactivatePaymentCardMutationOptions = Apollo.BaseMutationOptions<DeactivatePaymentCardMutation, DeactivatePaymentCardMutationVariables>;
export const RefundRequestDocument = gql`
    query refundRequest($id: String!) {
  refundRequest(id: $id) {
    ...RefundRequest
  }
}
    ${RefundRequestFragmentDoc}`;

/**
 * __useRefundRequestQuery__
 *
 * To run a query within a React component, call `useRefundRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundRequestQuery(baseOptions: Apollo.QueryHookOptions<RefundRequestQuery, RefundRequestQueryVariables>) {
        return Apollo.useQuery<RefundRequestQuery, RefundRequestQueryVariables>(RefundRequestDocument, baseOptions);
      }
export function useRefundRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefundRequestQuery, RefundRequestQueryVariables>) {
          return Apollo.useLazyQuery<RefundRequestQuery, RefundRequestQueryVariables>(RefundRequestDocument, baseOptions);
        }
export type RefundRequestQueryHookResult = ReturnType<typeof useRefundRequestQuery>;
export type RefundRequestLazyQueryHookResult = ReturnType<typeof useRefundRequestLazyQuery>;
export type RefundRequestQueryResult = Apollo.QueryResult<RefundRequestQuery, RefundRequestQueryVariables>;
export const RefundRequestsDocument = gql`
    query refundRequests($filter: RefundRequestFilter, $pagination: Pagination, $sort: Sort) {
  refundRequests(filter: $filter, pagination: $pagination, sort: $sort) {
    total
    data {
      ...RefundRequest
    }
  }
}
    ${RefundRequestFragmentDoc}`;

/**
 * __useRefundRequestsQuery__
 *
 * To run a query within a React component, call `useRefundRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useRefundRequestsQuery(baseOptions?: Apollo.QueryHookOptions<RefundRequestsQuery, RefundRequestsQueryVariables>) {
        return Apollo.useQuery<RefundRequestsQuery, RefundRequestsQueryVariables>(RefundRequestsDocument, baseOptions);
      }
export function useRefundRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefundRequestsQuery, RefundRequestsQueryVariables>) {
          return Apollo.useLazyQuery<RefundRequestsQuery, RefundRequestsQueryVariables>(RefundRequestsDocument, baseOptions);
        }
export type RefundRequestsQueryHookResult = ReturnType<typeof useRefundRequestsQuery>;
export type RefundRequestsLazyQueryHookResult = ReturnType<typeof useRefundRequestsLazyQuery>;
export type RefundRequestsQueryResult = Apollo.QueryResult<RefundRequestsQuery, RefundRequestsQueryVariables>;
export const GenerateRefundRequestDocument = gql`
    mutation GenerateRefundRequest($uid: String!, $expireSeconds: Int, $preview: Boolean) {
  generateRefundRequest(
    uid: $uid
    expireSeconds: $expireSeconds
    preview: $preview
  ) {
    ...RefundRequest
  }
}
    ${RefundRequestFragmentDoc}`;
export type GenerateRefundRequestMutationFn = Apollo.MutationFunction<GenerateRefundRequestMutation, GenerateRefundRequestMutationVariables>;

/**
 * __useGenerateRefundRequestMutation__
 *
 * To run a mutation, you first call `useGenerateRefundRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRefundRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRefundRequestMutation, { data, loading, error }] = useGenerateRefundRequestMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      expireSeconds: // value for 'expireSeconds'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useGenerateRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<GenerateRefundRequestMutation, GenerateRefundRequestMutationVariables>) {
        return Apollo.useMutation<GenerateRefundRequestMutation, GenerateRefundRequestMutationVariables>(GenerateRefundRequestDocument, baseOptions);
      }
export type GenerateRefundRequestMutationHookResult = ReturnType<typeof useGenerateRefundRequestMutation>;
export type GenerateRefundRequestMutationResult = Apollo.MutationResult<GenerateRefundRequestMutation>;
export type GenerateRefundRequestMutationOptions = Apollo.BaseMutationOptions<GenerateRefundRequestMutation, GenerateRefundRequestMutationVariables>;
export const ProcessRefundRequestDocument = gql`
    mutation ProcessRefundRequest($id: String!) {
  processRefundRequest(id: $id) {
    ...RefundRequest
  }
}
    ${RefundRequestFragmentDoc}`;
export type ProcessRefundRequestMutationFn = Apollo.MutationFunction<ProcessRefundRequestMutation, ProcessRefundRequestMutationVariables>;

/**
 * __useProcessRefundRequestMutation__
 *
 * To run a mutation, you first call `useProcessRefundRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessRefundRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processRefundRequestMutation, { data, loading, error }] = useProcessRefundRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<ProcessRefundRequestMutation, ProcessRefundRequestMutationVariables>) {
        return Apollo.useMutation<ProcessRefundRequestMutation, ProcessRefundRequestMutationVariables>(ProcessRefundRequestDocument, baseOptions);
      }
export type ProcessRefundRequestMutationHookResult = ReturnType<typeof useProcessRefundRequestMutation>;
export type ProcessRefundRequestMutationResult = Apollo.MutationResult<ProcessRefundRequestMutation>;
export type ProcessRefundRequestMutationOptions = Apollo.BaseMutationOptions<ProcessRefundRequestMutation, ProcessRefundRequestMutationVariables>;
export const ProcessedRefundRequestDocument = gql`
    mutation ProcessedRefundRequest($id: String!) {
  processedRefundRequest(id: $id) {
    ...RefundRequest
  }
}
    ${RefundRequestFragmentDoc}`;
export type ProcessedRefundRequestMutationFn = Apollo.MutationFunction<ProcessedRefundRequestMutation, ProcessedRefundRequestMutationVariables>;

/**
 * __useProcessedRefundRequestMutation__
 *
 * To run a mutation, you first call `useProcessedRefundRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessedRefundRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processedRefundRequestMutation, { data, loading, error }] = useProcessedRefundRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessedRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<ProcessedRefundRequestMutation, ProcessedRefundRequestMutationVariables>) {
        return Apollo.useMutation<ProcessedRefundRequestMutation, ProcessedRefundRequestMutationVariables>(ProcessedRefundRequestDocument, baseOptions);
      }
export type ProcessedRefundRequestMutationHookResult = ReturnType<typeof useProcessedRefundRequestMutation>;
export type ProcessedRefundRequestMutationResult = Apollo.MutationResult<ProcessedRefundRequestMutation>;
export type ProcessedRefundRequestMutationOptions = Apollo.BaseMutationOptions<ProcessedRefundRequestMutation, ProcessedRefundRequestMutationVariables>;
export const DeactivateRefundRequestDocument = gql`
    mutation DeactivateRefundRequest($id: String!) {
  deactivateRefundRequest(id: $id) {
    ...RefundRequest
  }
}
    ${RefundRequestFragmentDoc}`;
export type DeactivateRefundRequestMutationFn = Apollo.MutationFunction<DeactivateRefundRequestMutation, DeactivateRefundRequestMutationVariables>;

/**
 * __useDeactivateRefundRequestMutation__
 *
 * To run a mutation, you first call `useDeactivateRefundRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateRefundRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateRefundRequestMutation, { data, loading, error }] = useDeactivateRefundRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateRefundRequestMutation, DeactivateRefundRequestMutationVariables>) {
        return Apollo.useMutation<DeactivateRefundRequestMutation, DeactivateRefundRequestMutationVariables>(DeactivateRefundRequestDocument, baseOptions);
      }
export type DeactivateRefundRequestMutationHookResult = ReturnType<typeof useDeactivateRefundRequestMutation>;
export type DeactivateRefundRequestMutationResult = Apollo.MutationResult<DeactivateRefundRequestMutation>;
export type DeactivateRefundRequestMutationOptions = Apollo.BaseMutationOptions<DeactivateRefundRequestMutation, DeactivateRefundRequestMutationVariables>;
export const ActivateRefundRequestDocument = gql`
    mutation ActivateRefundRequest($id: String!) {
  activateRefundRequest(id: $id) {
    ...RefundRequest
  }
}
    ${RefundRequestFragmentDoc}`;
export type ActivateRefundRequestMutationFn = Apollo.MutationFunction<ActivateRefundRequestMutation, ActivateRefundRequestMutationVariables>;

/**
 * __useActivateRefundRequestMutation__
 *
 * To run a mutation, you first call `useActivateRefundRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateRefundRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateRefundRequestMutation, { data, loading, error }] = useActivateRefundRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<ActivateRefundRequestMutation, ActivateRefundRequestMutationVariables>) {
        return Apollo.useMutation<ActivateRefundRequestMutation, ActivateRefundRequestMutationVariables>(ActivateRefundRequestDocument, baseOptions);
      }
export type ActivateRefundRequestMutationHookResult = ReturnType<typeof useActivateRefundRequestMutation>;
export type ActivateRefundRequestMutationResult = Apollo.MutationResult<ActivateRefundRequestMutation>;
export type ActivateRefundRequestMutationOptions = Apollo.BaseMutationOptions<ActivateRefundRequestMutation, ActivateRefundRequestMutationVariables>;