import { Customer, RebillySubscription, PaymentCard } from '../bindings'
import isMatch from 'lodash/isMatch'
import first from 'lodash/first'

export const isPaymentCard = (card: any): card is PaymentCard => {
  return card && card.id && card.last4
}

export const isCustomer = (customer: any): customer is Customer => {
  return customer && customer.id && customer.email
}

export const isRebillySubscription = (subscription: any): subscription is RebillySubscription => {
  return subscription && subscription.id && subscription.renewalTime
}

export const isRebillySubscriptionWith = (fn: (subscription: RebillySubscription) => boolean) => (
  subscription: any
): subscription is RebillySubscription => {
  return isRebillySubscription(subscription) && fn(subscription)
}

export const isActivePaidRebillySubscription = isRebillySubscriptionWith(v =>
  isMatch(v, { status: 'active', billingStatus: 'paid' })
)

export const isCanceledPaidRebillySubscription = isRebillySubscriptionWith(v =>
  isMatch(v, { status: 'canceled', billingStatus: 'paid' })
)

export const isActiveRebillySubscription = isRebillySubscriptionWith(v => isMatch(v, { status: 'active' }))

export const isPendingRebillySubscription = isRebillySubscriptionWith(v => isMatch(v, { status: 'pending' }))

export const isCanceledRebillySubscription = isRebillySubscriptionWith(v => isMatch(v, { status: 'canceled' }))

export const isCurrentSubscription = (now: number) =>
  isRebillySubscriptionWith(v => (v.renewalTime ? new Date(v.renewalTime).valueOf() >= now : false))

export const isPastSubscription = (now: number) =>
  isRebillySubscriptionWith(v => (v.renewalTime ? new Date(v.renewalTime).valueOf() < now : false))

export const findCurrentSubscriptions = (subscriptions: any[]): RebillySubscription[] => {
  return subscriptions.filter(isCurrentSubscription(Date.now()))
}

export const findPastSubscriptions = (subscriptions: any[]): RebillySubscription[] => {
  return subscriptions.filter(isPastSubscription(Date.now()))
}

export const findMainSubscription = (subscriptions: any[]): RebillySubscription | null => {
  const current = findCurrentSubscriptions(subscriptions)
  return (
    current.find(isActivePaidRebillySubscription) ||
    current.find(isActiveRebillySubscription) ||
    current.find(isCanceledPaidRebillySubscription) ||
    first(current.filter(isRebillySubscription)) ||
    null
  )
}
