import React, { ReactNode, useMemo } from 'react'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  HttpLink,
} from '@apollo/client'
import { setContext } from 'apollo-link-context'
export * from './bindings'
export * from './components'
export * from './utils/types'

export type IStage = 'local' | 'development' | 'production'

const LOCAL_URI = 'http://localhost:8082'
const DEVELOPMENT_URI = 'https://dev-checkout-apollo.xy.company/graphql'
const PRODUCTION_URI = 'https://production-checkout-apollo.xy.company/graphql'
const LOCAL_REDEEM_URI = 'http://localhost:5000/xyo-network-1522800011804/us-central1/coin_subscriptions/redeem'
const PRODUCTION_REDEEM_URI =
  'https://us-central1-xyo-network-1522800011804.cloudfunctions.net/coin_subscriptions/redeem'

export const getCheckoutUri = (stage?: IStage) => {
  switch (stage) {
    case 'local':
      return LOCAL_URI
    case 'development':
      return DEVELOPMENT_URI
    default:
      return PRODUCTION_URI
  }
}

export const getRedeemUri = (stage?: IStage) => {
  switch (stage) {
    case 'local':
      return LOCAL_REDEEM_URI
    default:
      return PRODUCTION_REDEEM_URI
  }
}

export const getCheckoutClient = (stage?: IStage, getRequestContext?: () => Promise<any> | any) => {
  const uri = stage === 'local' ? LOCAL_URI : stage === 'development' ? DEVELOPMENT_URI : PRODUCTION_URI
  const redeem = (_: any, { email }: any) => {
    return fetch(`${getRedeemUri(stage)}/${email}`).then((res: any) => res.json())
  }

  const authLink: any = setContext(async (op, { headers }) => {
    const ctx = await getRequestContext?.()
    return {
      ...ctx,
      headers: {
        ...headers,
        ...ctx?.headers
      },
    }
  })

  return new ApolloClient({
    link: concat(authLink, new HttpLink({ uri })),
    cache: new InMemoryCache(),
    resolvers: {
      Query: {
        redeem,
      },
      Mutate: {
        redeem,
      },
    }
  })
}

export const CheckoutProvider = ({
  stage,
  children,
  getRequestContext,
}: {
  children: ReactNode
  stage?: IStage
  getRequestContext?: () => Promise<any> | any
}) => {
  const client = useMemo(() => getCheckoutClient(stage, getRequestContext), [stage])
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
